import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function Footer(props) {
    return (
        <Stack>
            {JSTool.isCR() ? <Footer_CR /> : <Footer_APJ />}
        </Stack>
    );
}


function Footer_APJ(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector(state => state.settingData);

    function privacyTap() {
        window.open(window.location + 'privacy');
    }

    function termsTap() {
        window.open(window.location + 'terms');
    }

    return (
        <Stack sx={{
            width: 1,
            bgcolor: 'black',
            justifyContent: 'center',
            alignItems: 'center',
            py: 2.5,
        }}>
            <Stack sx={{
                height: 1,
                width: settingData.isMobile ? 1 : '900rem',
                justifyContent: 'center',
            }}>

                {/* 上一行 */}
                <Stack sx={{
                    width: 1,
                    height: '40rem',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Box component={'img'}
                        src={JSTool.getImgPath('logo4.png')}
                        sx={{
                            height: '60rem'
                        }} />


                    <Box component={'img'}
                        src={JSTool.getImgPath('apj_logo.png')}
                        sx={{
                            height: '35rem',
                            ml: settingData.isMobile ? 2 : 3.5,
                        }} />

                    <Typography
                        onClick={privacyTap}
                        sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14rem',
                            ml: settingData.isMobile ? 2 : 4,
                            borderBottom: 1,
                            borderColor: 'white',
                            transitionDuration: '0.3s',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.07)',
                            }
                        }}>
                        Privacy Policy
                    </Typography>

                    <Typography
                        onClick={termsTap}
                        sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14rem',
                            ml: settingData.isMobile ? 2 : 4,
                            borderBottom: 1,
                            borderColor: 'white',
                            transitionDuration: '0.3s',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.07)',
                            }
                        }}>
                        Terms of Service
                    </Typography>

                </Stack>

                {/* 下一行 */}
                <Stack sx={{
                    width: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                }}>

                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: settingData.isMobile ? '16rem' : '12rem',
                    }}>
                        Copyright:
                    </Typography>

                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '12rem',
                        ml: 1,
                        display: settingData.isMobile ? 'none' : 'flex'
                    }}>
                        ⒸKugane Maruyama,
                    </Typography>

                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '12rem',
                        ml: 1,
                        display: settingData.isMobile ? 'none' : 'flex'
                    }}>
                        PUBLISHED BY KADOKAWA CORPORATION/OVERLORD3PARTENERS
                    </Typography>

                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: settingData.isMobile ? '16rem' : '12rem',
                        ml: 1,
                        display: settingData.isMobile ? 'flex' : 'none'
                    }}>
                        ⒸKM,K/OL3P
                    </Typography>


                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: settingData.isMobile ? '16rem' : '12rem',
                        ml: 1,
                    }}>
                        © A PLUS JAPAN, Inc. All Rights Reserved
                    </Typography>

                </Stack>

            </Stack>

        </Stack>

    );
}


function Footer_CR(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector(state => state.settingData);

    function privacyTap() {
        window.open('https://www.sonypictures.com/corp/privacy.html');
    }

    function termsTap() {
        window.open('https://www.crunchyroll.com/games/terms');
    }

    function customerTap() {
        window.open('https://games.help.crunchyroll.com/hc/en-us');
    }

    function choicesTap() {
        window.open('https://privacyportal-cdn.onetrust.com/dsarwebform/d19e506f-1a64-463d-94e4-914dd635817d/b9eb997c-9ede-451b-8fd4-29891782a928.html');
    }

    return (
        <Stack sx={{
            width: 1,
            bgcolor: 'black',
            justifyContent: 'center',
            alignItems: 'center',
            py: 2,
        }}>
            <Stack sx={{
                height: 1,
                width: settingData.isMobile ? 1 : '1100rem',
                justifyContent: 'center',
                flexDirection: settingData.isMobile ? 'column' : 'row',
            }}>

                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    mr: 3,
                    mb: 1,
                    justifyContent: settingData.isMobile ? 'center' : 'flex-start',
                    width: settingData.isMobile ? 1 : 'auto',
                }}>

                    <Box component={'img'}
                        src={JSTool.getImgPath('logo4.png')}
                        sx={{
                            height: '65rem'
                        }} />

                    <Stack sx={{
                        ml: 3
                    }}>
                        <Box component={'img'}
                            src={JSTool.getImgPath('sony.png')}
                            sx={{
                                height: '13rem',
                                objectFit: 'contain',
                                mb: settingData.isMobile ? 0.5 : 1.7,
                            }} />

                        <Box component={'img'}
                            src={JSTool.getImgPath('cr_logo_new.png')}
                            sx={{
                                height: '30rem',
                            }} />

                    </Stack>



                </Stack>

                <Stack>
                    {/* 上一行 */}
                    <Stack sx={{
                        width: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <Typography
                            onClick={privacyTap}
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '14rem',
                                ml: settingData.isMobile ? 2 : 4,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.07)',
                                }
                            }}>
                            Privacy Policy
                        </Typography>

                        <Typography
                            onClick={termsTap}
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '14rem',
                                ml: settingData.isMobile ? 2 : 4,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.07)',
                                }
                            }}>
                            Terms of Service
                        </Typography>

                        <Typography
                            onClick={customerTap}
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '14rem',
                                ml: settingData.isMobile ? 2 : 4,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.07)',
                                }
                            }}>
                            Customer Service
                        </Typography>

                    </Stack>

                    <Stack sx={{
                        width: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        my: 1,
                    }}>

                        <Typography
                            onClick={choicesTap}
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: '14rem',
                                ml: settingData.isMobile ? 2 : 4,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.07)',
                                }
                            }}>
                            Your Privacy Choices
                        </Typography>
                        <Box component={'img'}
                            src={
                                JSTool.getImgPath('cr_tag.png')
                            }
                            sx={{ height: '20rem', ml: 1 }}
                        />


                    </Stack>

                    {/* 下一行 */}
                    <Stack sx={{
                        width: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <Typography sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '12rem',
                            ml: 1,
                            textAlign: 'center',
                        }}>
                            ©2024 Crunchyroll, LLC. All Rights Reserved. CRUNCHYROLL, CRUNCHYROLL GAMES and the Crunchyroll logo are trademarks of Crunchyroll, LLC.
                        </Typography>

                    </Stack>
                </Stack>

            </Stack>

        </Stack>

    );
}