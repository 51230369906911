
import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import MobileMenu from '../component/MobileMenu';
import * as rdd from 'react-device-detect';
import LeftDownload from '../component/LeftDownload';
import RightBottom from '../component/RightBottom';

import CookieView from '../component/CookieView';

import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Footer from './Footer';


export default function HomePageMobile(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {
        $("#home_page_view").on('scroll', () => {
            let x = $('#home_page_view').scrollTop();
            processScroll(x);
        });
    }, []);

    function processScroll(x) {
        //显示隐藏
        let h = settingData.innerHeight;
        if (x >= h - $("#nav_view").height()) {
            JSTool.setViewHidden(true);
        } else {
            JSTool.setViewHidden(false);
        }
    }

    function menuTap() {
        if (settingObj.showMenu) {
            JSTool.hideMenu();
        } else {
            JSTool.showMenu('menu_view');
        }
    }

    function logoTap() {
        window.location.href = window.location.origin;
    }

    return (
        <Stack
            sx={{
                width: 1,
                height: settingData.innerHeight,
                userSelect: 'none'
            }}>

            <CookieView />

            {/* 手机端导航栏 */}
            <Stack
                id='nav_view'
                sx={{
                    display: settingData.isMobile ? 'flex' : 'none',
                    width: 1,
                    height: rdd.isIPad13 ? '90px' : '50px',
                    bgcolor: '#000000ea',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 3,
                }}>
                <Box
                    onClick={logoTap}
                    component={'img'}
                    src={JSTool.getImgPath('logo4.png')}
                    sx={{
                        height: 0.7,
                        ml: 3,
                    }} />

                <Box sx={{ flex: 1 }} />

                <Box
                 onClick={() => {
                    JSTool.twitterTap();
                }}
                    component={'img'}
                    src={JSTool.getImgPath('twitter.png')}
                    sx={{
                        mr: 2,
                        height: 0.6,
                    }} />

                {/* <Box
                    component={'img'}
                    src={JSTool.getImgPath('facebook.png')}
                    sx={{
                        mr: 2,
                        height: 0.6,
                    }} /> */}

                <Box
                   onClick={() => {
                    JSTool.discordTap();
                }}
                    component={'img'}
                    src={JSTool.getImgPath('discord.png')}
                    sx={{
                        height: 0.6,
                        mr: '100rem'
                    }} />

                {/* 菜单动画控件 */}
                <Box
                    onClick={menuTap}
                    component={'div'}
                    className='btn-nav'>
                    <i className="draw"></i>
                </Box>
                <MobileMenu />

            </Stack>

            <Stack
                id='home_page_view'
                sx={{
                    width: 1,
                    overflow: 'auto',
                }}>
                <Stack>
                    <Page0 anchorId={'scroll_to0'} />
                    <Page1 anchorId={'scroll_to1'} />
                    <Page2 anchorId={'scroll_to2'} />
                    <Page3 anchorId={'scroll_to3'} />
                    <Page4 anchorId={'scroll_to4'} />
                    <Footer />
                </Stack>
            </Stack>


        </Stack>
    );


}