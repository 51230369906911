import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from './store/Store';
import { Provider } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from './store/SettingSlice';
import * as rdd from 'react-device-detect';
import { Box, Stack, Typography } from '@mui/material';
import LoadingAndHint from './component/LoadingAndHint';
import { hideMenu } from './store/JSTool';
import $ from 'jquery';
import * as APJHttp from './store/APJHttp';
import * as JSTool from './store/JSTool';

import HomePage from './page/HomePage';
import HomePageMobile from './page/HomePageMobile';
import SubmitEmailAlert from './component/SubmitEmailAlert';
import NewsDetailAlert from './page/NewsDetailAlert';
import TermsPage from './page/TermsPage';
import PrivacyPage from './page/PrivacyPage';
import DownloadPage from './page/DownloadPage';
import DownloadPageMobile from './page/DownloadPageMobile';
import OneLink from './page/OneLink';
import OneLinkOverlor from './page/OneLinkOverlor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/download' element={<AppWraper pc={<DownloadPage />} mb={<DownloadPageMobile />} />} />
                <Route path='/terms' element={<AppWraper pc={<TermsPage />} mb={<TermsPage />} />} />
                <Route path='/privacy' element={<AppWraper pc={<PrivacyPage />} mb={<PrivacyPage />} />} />
                <Route path='/onelink' element={<OneLink />} />
                <Route path='/overlord' element={<OneLinkOverlor />} />
            </Routes>
            <LoadingAndHint />
            <SubmitEmailAlert />
            <NewsDetailAlert />
        </BrowserRouter>
    </Provider>
);

function App() {
    const [flag, setFlag] = React.useState(0);
    React.useEffect(() => {
        // setFlag(1);
        APJHttp.getIPInfo((pass) => {
            if (pass) {
                setFlag(1);
            } else {
                setFlag(-1);
            }
        })
    }, []);

    return (
        <Stack>
            {flag == 1 &&
                <AppWraper pc={<HomePage />} mb={<HomePageMobile />} />
            }
            {flag == -1 && <ForbiddenView />}
        </Stack>
    )
}

function ForbiddenView() {
    function isMobile() {
        return rdd.isMobile || rdd.isIPad13 || rdd.isIPhone13 || window.innerWidth < 1025;
    }

    return (
        <Box
            component={'img'}
            src={isMobile() ? JSTool.getImgPath('forbidden_m.png') : JSTool.getImgPath('forbidden.png')}
            sx={{
                width: 1,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'black',
                justifyContent: 'center',
                objectFit: 'cover',
            }} />
    );
}


function AppWraper(props) {

    const settingData = useSelector(state => state.settingData);
    const dispatch = useDispatch();
    let resizeTimer;

    React.useEffect(() => {
        $(window).on("resize", changeSize);
        dispatch(settingActions.setIsMobile(isMobile()));
    }, []);

    function changeSize(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            dispatch(settingActions.refreshInnerHeight());
        }, 250);
    }

    function isMobile() {
        return rdd.isMobile || rdd.isIPad13 || rdd.isIPhone13 || window.innerWidth < 1025;
    }

    return (
        <Stack sx={{
            fontSize: '16rem'//默认值
        }}>
            {isMobile() && props.mb}
            {!isMobile() && props.pc}

            {isMobile() &&
                <Stack
                    id='_mask_view'
                    onClick={() => {
                        hideMenu();
                    }}
                    sx={{
                        position: 'absolute',
                        zIndex: 2,
                        bgcolor: '#00000099',
                        width: 1,
                        height: settingData.innerHeight,
                        display: 'none',
                    }} />
            }
        </Stack>
    )
}