import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/JSTool';
import $ from 'jquery';
import TitleView from '../component/TitleView';
import * as rdd from 'react-device-detect';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import HeroDic from '../store/hero.json';

export default function Page3(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);

    const [roleName, setRoleName] = React.useState('b_role0');
    const [bookName, setBookName] = React.useState('b_role0');
    const [bookDic, setBookDic] = React.useState(HeroDic['b_role0']);

    const imgAryPC = [
        'b_role0', 'b_role1', 'b_role2', 'b_role3',
        'b_role4', 'b_role5', 'b_role6', 'b_role7',
    ];

    function roleTap(name) {
        setRoleName(name);
        bookTap(name);
    }

    function bookTap(name) {
        setBookName(name);
        setBookDic(HeroDic[name]);
    }

    function indexChanged() {

    }

    return (
        <Stack
            id={'page3'}
            sx={{
                overflow: 'hidden',
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: settingData.isMobile ? `url(${getImgPath('bg3_9_mb.png')})` : `url(${getImgPath('bg3_9.png')})`,
                alignItems: 'center',
                position: 'relative',
                pb: 5,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }}>

            <TitleView title={'CHARACTERS'} anchorId={props.anchorId} mt={1} />

            <Stack sx={{
                mt: settingData.isMobile ? 4 : 8,
                height: '160rem',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                width: settingData.isMobile ? 1 : '1200rem',
            }}>
                {/* 黑色阴影背景 */}
                <Stack sx={{
                    flexDirection: 'row',
                    height: 1,
                }}>
                    <Box sx={{
                        width: '500rem',
                        background: 'linear-gradient(90deg, #00000000 , #000000cc)',
                    }} />
                    <Box sx={{
                        display: settingData.isMobile && 'none',
                        width: '200rem',
                        background: '#000000cc',
                    }} />
                    <Box sx={{
                        width: '500rem',
                        background: 'linear-gradient(-90deg, #00000000 , #000000cc)',
                    }} />
                </Stack>

                {/* 头像容器 */}
                <Stack sx={{
                    width: 1,
                    flexDirection: 'row',
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('swip_left.png')}
                        sx={{
                            display: !settingData.isMobile && 'none',
                            mr: 1,
                            height: '35rem',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            }
                        }} />

                    <Swiper
                        style={{
                            width: settingData.isMobile ? '75%' : '90%',
                            textAlign: 'center',
                            margin: 0,
                            paddingLeft: '10px',
                            height: '100%',
                        }}

                        onSwiper={(sp) => {
                            setSwp(sp);
                        }}

                        onSlideChangeTransitionEnd={(sp) => {
                            indexChanged();
                        }}

                        slideToClickedSlide={settingData.isMobile}
                        slidesPerView={settingData.isMobile ? 4 : 8}
                        loop={false}>
                        {
                            imgAryPC.map((item, i) =>
                                <SwiperSlide key={i} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <HeroItem imgName={item} selectedName={roleName} tap={roleTap} />
                                </SwiperSlide>
                            )
                        }
                    </Swiper>

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('swip_right.png')}
                        sx={{
                            display: !settingData.isMobile && 'none',
                            ml: 1,
                            height: '35rem',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            }
                        }} />

                </Stack>
            </Stack>

            {/* ----------------------书本---------------------- */}
            <Stack
                id={props.id}
                sx={{
                    mt: 2,
                    width: settingData.isMobile ? 0.9 : '1050rem',
                    height: settingData.isMobile ? (rdd.isIPad13 ? '1200rem' : '1250rem') : '550rem',
                    backgroundSize: '100% 100%',
                    backgroundImage: settingData.isMobile ? `url(${getImgPath('book/book_bg_mb2.png')})` : `url(${getImgPath('book/book_bg.png')})`,
                    flexDirection: settingData.isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    zIndex: 1,
                }}>

                {/* 左边 */}
                <Stack sx={{
                    width: settingData.isMobile ? 0.85 : 0.4,
                    ml: settingData.isMobile ? 0 : 10,
                    alignItems: 'center',
                }}>
                    <Stack sx={{
                        width: 1,
                        height: settingData.isMobile ? '160rem' : '130rem',
                        backgroundSize: '100% 100%',
                        backgroundImage: `url(${getImgPath('book/book_title_bg.png')})`,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        mt: settingData.isMobile ? 2 : 0,

                    }}>
                        <Typography sx={{
                            fontSize: settingData.isMobile ? 18 : 25,
                            color: 'black',
                            lineHeight: '30rem',
                            mt: 1.5,
                            fontFamily: window.MyFont,
                        }}>
                            {bookDic['name']}
                        </Typography>

                        <Typography sx={{
                            fontSize: settingData.isMobile ? 10 : 14,
                            color: 'black',
                            lineHeight: '20rem',
                            fontFamily: window.MyFont,
                            textAlign: 'center',
                            mt: settingData.isMobile ? 0.5 : 0,
                        }}>
                            {bookDic['position']}
                        </Typography>
                    </Stack>

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath(`role/${bookDic['left_img']}`)}
                        sx={{
                            width: settingData.isMobile ? 0.9 : 0.85,
                            height: settingData.isMobile ? '450rem' : '320rem',
                            mt: 0.5,
                            mb: 2,
                        }} />
                </Stack>

                {/* 右边 */}
                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: settingData.isMobile ? 0 : 8,
                    position: 'relative',
                    width: settingData.isMobile ? 0.8 : 'auto',
                }}>

                    <Stack>
                        <Stack sx={{
                            width: settingData.isMobile ? '230rem' : '190rem',
                            height: settingData.isMobile ? '390rem' : '330rem',
                            backgroundSize: '100% 100%',
                            backgroundImage: `url(${getImgPath('book/book_detail_bg.png')})`,
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Stack
                                className='hideScrollBar'
                                sx={{
                                    height: settingData.isMobile ? '300rem' : '250rem',
                                    overflow: 'scroll',
                                    px:1,
                                }}>
                                <Typography sx={{
                                    fontSize: settingData.isMobile ? 10 : 16,
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textAlign: 'center',
                                    fontFamily: window.MyFont,
                                    mx: 1,
                                }}>
                                    {bookDic['title']}
                                </Typography>

                                <Typography sx={{
                                    fontSize: settingData.isMobile ? 8 : 13,
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textAlign: 'center',
                                    mt: settingData.isMobile ? 1 : 2,
                                    fontFamily: window.MyFont,
                                    mx: 1,
                                }}>
                                    {bookDic['detail_name']}
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: settingData.isMobile ? 8 : 13,
                                        fontWeight: 'bold',
                                        color: settingData.isMobile ? '#666666' : 'black',
                                        lineHeight: '22rem',
                                        textAlign: 'center',
                                        mx: 1.3,
                                        mt: 1,
                                        overflow: 'visible'
                                    }}>
                                    {bookDic['detail']}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack sx={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            mt: 1,
                            zIndex: 3,
                        }}>
                            {
                                bookDic['icons'].map((value, index) => {
                                    return <IconView key={value} imgName={value} selectedName={bookName} tap={bookTap} />
                                })
                            }

                        </Stack>
                    </Stack>

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath(`role/${bookDic['detail_img']}`)}
                        sx={{
                            position: 'absolute',
                            height: settingData.isMobile ? '550rem' : '450rem',
                            left: settingData.isMobile ? '230rem' : '170rem',
                        }} />

                </Stack>
            </Stack>

            {/* 底部渐变色 */}
            <Box sx={{
                width: 1,
                height: '250px',
                background: 'linear-gradient(#080B0D00 , #000000)',
                zIndex: 0,
                bottom: 0,
                position: 'absolute',
            }} />

            <Box sx={{
                display: settingData.isMobile ? 'flex' : 'none',
                width: 1,
                height: '30px',
                background: 'linear-gradient(#010000 , #080B0D00)',
                zIndex: 1,
                top: 0,
                position: 'absolute',
            }} />

        </Stack>
    );
}

function IconView(props) {
    const settingData = useSelector(state => state.settingData);

    return (
        <Box
            onClick={() => {
                props.tap(props.imgName);
            }}
            component={'img'}
            src={getImgPath(`role/${props.imgName}.png`)}
            sx={{
                cursor: 'pointer',
                mx: 0.5,
                width: settingData.isMobile ? '90rem' : '80rem',
                height: settingData.isMobile ? '90rem' : '80rem',
                transitionDuration: '0.3s',
                '&:hover': {
                    transform: 'scale(1.1)',
                    filter: 'drop-shadow(0px 0px 10px #CC19D1)'
                }
            }} />
    );
}

function HeroItem(props) {

    const [imgName, setImgName] = React.useState(`role/${props.imgName}.png`);
    const [show, setShow] = React.useState(false);


    React.useEffect(() => {

    }, [props.selectedName]);

    function mOver() {
        setImgName(`role/${props.imgName}_blue.png`);
        setShow(true);
    }

    function checkHight() {
        // if (props.selectedName == props.imgName) {
        //     mOver();
        // } else {
        setImgName(`role/${props.imgName}.png`);
        setShow(false);
        // }
    }

    return (
        <Box
            onClick={() => {
                props.tap(props.imgName);
            }}
            onMouseOver={mOver}
            onMouseLeave={checkHight}
            component={'img'}
            src={getImgPath(imgName)}
            sx={{
                height: '125rem',
                width: '125rem',
                cursor: 'pointer',
                transform: show && 'scale(1.2)',
                transitionDuration: '0.3s',
                filter: show && 'drop-shadow(0px 0px 20px #CC19D1)',
                '&:hover': {
                    transform: 'scale(1.2)',
                    filter: 'drop-shadow(0px 0px 20px #CC19D1)'
                }
            }} />
    );

}