import { Stack, Box, Typography, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function LoadingAndHint(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack>
            {/* loading view */}
            <Backdrop
                sx={{ color: '#fff', zIndex: 99999 }}
                open={settingData.showLoading}
                onClick={() => { }}>
                <CircularProgress color="inherit" />
            </Backdrop>


            {/* 提示 */}
            <Snackbar open={!JSTool.isEmpty(settingData.showToast)} autoHideDuration={1500} onClose={() => {
                dispatch(settingActions.showToast(null));
            }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <Box
                    sx={{
                        minWidth: '250px',
                        height: '45px',
                        lineHeight: '45px',
                        borderRadius: '5px',
                        bgcolor: '#ffffff',
                        color: 'black',
                        textAlign: 'center',
                        px: 3,
                    }}>
                    {settingData.showToast}
                </Box>
            </Snackbar>
        </Stack>
    );

}