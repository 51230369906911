import { Stack, Box, Typography, } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function CookieView(props) {

    const [show, setShow] = React.useState(false);
    const settingData = useSelector(state => state.settingData);
    const cr_txt = 'Our site uses cookies and other technologies to run this site, improve its operation and to deliver ads and other marketing materials to you. To learn more about how we use cookies, please see our <a target="_blank" href="https://www.sonypictures.com/corp/privacy.html">Privacy Policy</a> . To manage the cookies we use with you, please <a target="_blank" href="https://privacyportal-cdn.onetrust.com/dsarwebform/d19e506f-1a64-463d-94e4-914dd635817d/b9eb997c-9ede-451b-8fd4-29891782a928.html">Click Here</a>.'
    const apj_txt = 'Our site uses cookies and other technologies to run this site, improve its operation and to deliver ads and other marketing materials to you. To learn more about how we use cookies, please see our <a target="_blank" href="https://lon.aplus-games.com/privacy">Privacy Policy</a> .'


    React.useEffect(() => {
        if (!JSTool.getCookie("cookieConsent")) {
            setShow(true);
        }
    });

    function acceptTap() {
        setShow(false);
        JSTool.setCookie("cookieConsent", "accepted", 365);
    }

    function declineTap() {
        setShow(false);
        JSTool.setCookie("cookieConsent", "rejected", 365);
    }

    return (
        <Stack sx={{
            display: show ? 'flex' : 'none',
            width: 1,
            minHeight:'60rem',
            py: settingData.isMobile ? 2.3 : 3,
            bgcolor: 'black',
            flexDirection: settingData.isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            zIndex: 3,
            bottom: 0,
        }}>

            <Box
                className='cookie_view'
                component={'div'}
                sx={{
                    color: 'white',
                    maxWidth: '750rem',
                    fontSize: settingData.isMobile ? '15px' : '15rem',
                    px: settingData.isMobile ? 5 : 0,
                    fontFamily: 'serif'
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        `
                         ${JSTool.isCR() ? cr_txt : apj_txt}
                         `
                }} />

            <Stack sx={{
                flexDirection: 'row',
                mt: settingData.isMobile && 1,
            }}>

                <Typography
                    onClick={declineTap}
                    sx={{
                        color: 'white',
                        fontSize: settingData.isMobile ? '15px' : '19rem',
                        ml: 3.5,
                        border: 1,
                        py: 0.5,
                        px: 1,
                        borderColor: 'white',
                        cursor: 'pointer',
                        fontFamily: 'serif'
                    }}>
                    Decline
                </Typography>

                <Typography
                    onClick={acceptTap}
                    sx={{
                        color: 'white',
                        fontSize: settingData.isMobile ? '15px' : '19rem',
                        ml: 3.5,
                        border: 1,
                        py: 0.5,
                        px: 1,
                        borderColor: 'white',
                        cursor: 'pointer',
                        fontFamily: 'serif'
                    }}>
                    Accept
                </Typography>

            </Stack>

        </Stack>
    );

}