
import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/JSTool';
import * as APJHttp from '../store/APJHttp';


export default function Page0(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function registerTap() {
        if(!settingData.isMobile){
            dispatch(settingActions.showRegisterAlert(true));
            JSTool.trackEvent('show_email_pop');
        }else{
            if(JSTool.isIOS){
                JSTool.appleTap();
            }else{
                JSTool.googleTap();
            }
        }
    }

    function appleTap() {
        JSTool.appleTap();
    }

    function googleTap() {
        JSTool.googleTap();
    }

    return (
        <Stack
            id={props.anchorId}
            sx={{
                position: 'relative',
                width: 1,
                height: settingData.innerHeight,
                overflow: 'hidden',
                alignItems: 'center',
            }}>

            <Box
                component={'video'}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
                disablePictureInPicture={true}
                poster={settingData.isMobile ? getImgPath('bg0_3_mb.png') : getImgPath('bg_mv2_img.jpeg')}
                src={settingData.isMobile ? null : (getImgPath('bg_mv3.mp4'))}
                sx={{
                    width: 1,
                    height: 1,
                    objectFit: 'cover',
                }} />

            {/* 下载按钮 */}
            <Stack sx={{
                position: 'absolute',
                zIndex: 2,
                bottom: '0',
                width: 1,
                alignItems: 'center',
            }}>

                <Box
                    onClick={registerTap}
                    component={'img'}
                    src={getImgPath('pre_register.png')}
                    sx={{
                        display: props.isDownload ? 'none' : 'block',
                        height: '110rem',
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(1.02)',
                        }
                    }} />

                {/* PC */}
                <Stack sx={{
                    mt: -2,
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    display: settingData.isMobile && 'none',
                }}>
                    <Box
                        component={'img'}
                        src={getImgPath('draws.png')}
                        sx={{
                            height: '120rem'
                        }} />

                    <Stack sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        height: '60rem',
                        mr: 10,
                    }}>
                        <Box
                            onClick={appleTap}
                            component={'img'}
                            src={getImgPath('pre_blue_apple.png')}
                            sx={{
                                height: 1,
                                mr: 1,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                }
                            }} />

                        <Box
                            onClick={googleTap}
                            component={'img'}
                            src={getImgPath('pre_blue_google.png')}
                            sx={{
                                height: 1,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                }
                            }} />

                    </Stack>

                </Stack>

                {/* Mobile */}
                <Stack sx={{
                    display: !settingData.isMobile && 'none',
                }}>
                    <Stack sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100rem',
                    }}>
                        <Box
                            onClick={appleTap}
                            component={'img'}
                            src={getImgPath('pre_blue_apple.png')}
                            sx={{
                                height: 1,
                                mr: 1,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                }
                            }} />

                        <Box
                            onClick={googleTap}
                            component={'img'}
                            src={getImgPath('pre_blue_google.png')}
                            sx={{
                                height: 1,
                                transitionDuration: '0.3s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                }
                            }} />
                    </Stack>

                    <Box
                        component={'img'}
                        src={getImgPath('free2.png')}
                        sx={{
                            width: 1,
                        }} />

                </Stack>

            </Stack>

            {/* 底部渐变色 */}
            <Box sx={{
                width: 1,
                height: '100px',
                background: 'linear-gradient(#00000000 , #000000cc)',
                position: 'absolute',
                bottom: 0,
            }} />

        </Stack>
    );
}
