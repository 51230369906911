import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import $ from 'jquery';
import * as rdd from 'react-device-detect';
import * as APJHttp from '../store/APJHttp';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';

export default function NewsDetailAlert(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const [index, setIndex] = React.useState(0);
    const [showLeft, setShowLeft] = React.useState(false);
    const [showRight, setShowRight] = React.useState(false);

    React.useEffect(() => {
        checkButtonShow();
    }, [settingObj.showNewsDetailIndex]);

    function checkButtonShow() {
        if (settingObj.showNewsDetailIndex >= 1 && settingObj.showNewsDetailIndex < settingData.newsList.length) {
            setShowLeft(true);
        } else {
            setShowLeft(false);
        }

        if (settingObj.showNewsDetailIndex >= 0 && settingObj.showNewsDetailIndex < settingData.newsList.length - 1) {
            setShowRight(true);
        } else {
            setShowRight(false);
        }
    }

    function leftTap() {
        if (swp) {
            swp.slidePrev();
        }
    }

    function rightTap() {
        if (swp) {
            swp.slideNext();
        }
    }

    function indexChanged() {
        if (swp && settingData.newsList != null) {
            dispatch(settingActions.showNewsDetailIndex(swp.realIndex));
            checkButtonShow();
        }
    }

    return (
        <Dialog
            fullScreen={true}
            PaperProps={{
                style: {
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000033',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                },
            }}
            open={settingData.showNewsDetailIndex >= 0}>

            <Stack sx={{
                width: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <Box
                    onClick={leftTap}
                    component={'img'}
                    src={JSTool.getImgPath('swip_left.png')}
                    sx={{
                        visibility: showLeft ? 'visible' : 'hidden',
                        height: settingData.isMobile ? '30rem' : '60rem',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.3)',
                        },
                    }} />

                <Swiper style={{
                    margin: 0,
                    width: settingData.isMobile ? window.innerWidth * 0.9 : '1100rem',
                }}
                    resistanceRatio={0}
                    loop={false}
                    slidesPerView={1}
                    centeredSlides={true}
                    slideToClickedSlide={true}

                    onSlideChangeTransitionEnd={(sp) => {
                        indexChanged();
                    }}

                    onSwiper={(swiper) => {
                        setSwp(swiper);
                        setTimeout(() => {
                            if (settingObj.showNewsDetailIndex > 0) {
                                swiper.slideTo(settingObj.showNewsDetailIndex);
                            }
                        }, 50);
                    }}>
                    {settingData.newsList && settingData.newsList.map((item, index) => {
                        return <SwiperSlide key={index}>  <NewsDetail item={item} index={index} /></SwiperSlide>;
                    })}
                </Swiper>

                <Box
                    onClick={rightTap}
                    component={'img'}
                    src={JSTool.getImgPath('swip_right.png')}
                    sx={{
                        visibility: showRight ? 'visible' : 'hidden',
                        height: settingData.isMobile ? '30rem' : '60rem',
                        objectFit: 'cover',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'scale(1.3)',
                        }
                    }} />
            </Stack>
        </Dialog>
    );
}


function NewsDetail(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [content, setContent] = React.useState('');

    React.useEffect(() => {
        requestDetail();
    }, [settingData.showNewsDetailIndex]);

    function requestDetail() {
        const localInfo = APJHttp.getNewsDetailLocal(props.item.id);
        if (localInfo != null) {
            setContent(localInfo);
            return;
        }

        if (settingObj.showNewsDetailIndex >= 0 && (
            settingObj.showNewsDetailIndex == props.index || 
            settingObj.showNewsDetailIndex == props.index-1 ||
            settingObj.showNewsDetailIndex == props.index+1
        )) {
            APJHttp.getNewsDetail(props.item.id, (data) => {
                setContent(data);
            }, (code, msg) => {
                dispatch(settingActions.showToast(`code ${code}: ${msg}`));
            });
        }
    }

    function closeTap() {
        dispatch(settingActions.showNewsDetailIndex(-1));
    }

    return (
        <Stack sx={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        }}>
            {/* 内容 */}
            <Stack sx={{
                width: settingData.isMobile ? window.innerWidth * 0.85 : '1100rem',
                height: settingData.isMobile ? settingData.innerHeight * 0.85 : settingData.innerHeight * 0.9,
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: `url(${JSTool.getImgPath('money_bg.png')})`,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                overflow: 'hidden',
            }}>
                <Box
                    className='newsDetailContent hideScrollBar'
                    component={'div'}
                    sx={{
                        color: 'black',
                        mx: settingData.isMobile ? '30rem' : '100rem',
                        mt: '-10px',
                        height: settingData.isMobile ? 0.78 : 0.73,
                        overflow: 'auto',

                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                            ${content}
                        `
                    }} />

            </Stack>

            {/* 关闭按钮 */}
            <Box
                component={'img'}
                onClick={closeTap}
                src={JSTool.getImgPath('close1.png')}
                sx={{
                    position: 'absolute',
                    alignSelf: 'flex-end',
                    width: settingData.isMobile ? '28rem' : '20rem',
                    zIndex: 10,
                    top: 0,
                    right: 0,
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'rotate(180deg)',
                    }
                }} />
        </Stack>

    );
}