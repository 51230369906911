import { Stack, Box, } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function RightBottom(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);


    function topTap() {
        JSTool.scrollToTop('home_page_view');
    }

    return (
        <Stack
            id={'home_page_right_view'}
            sx={{
                display: 'none',
                position: 'fixed',
                zIndex: 999,
                right: settingData.isMobile ? 0 : 20,
                bottom: 0,
            }}>

            <Stack sx={{
                alignItems: 'center',
                flexDirection: 'column',
            }}>

                <Box
                    component={'img'}
                    src={JSTool.getImgPath('top_img2.png')}
                    sx={{
                        zIndex: 1,
                        position: 'absolute',
                        height: '65rem',
                        right: '5rem',
                        top: '10rem',
                    }} />

                <Box
                    onClick={topTap}
                    sx={{
                        zIndex: 5,
                        position: 'absolute',
                        height: '65rem',
                        width: '60rem',
                        right: '5rem',
                        top: '10rem',
                        cursor: 'pointer',
                    }} />

                <Box
                    component={'img'}
                    src={JSTool.getImgPath('mouse.png')}
                    sx={{
                        zIndex: 2,
                        height: '190rem',
                        pb: 6,
                    }} />

                <Box
                    component={'img'}
                    src={JSTool.getImgPath('follow_bg.png')}
                    sx={{
                        zIndex: 3,
                        position: 'absolute',
                        top: '100rem',
                        height: '115rem',
                    }} />

                <Stack sx={{
                    zIndex: 4,
                    position: 'absolute',
                    top: '150rem',
                    flexDirection: 'row',
                    mt:0.5,
                }}>
                    <Box
                     onClick={() => {
                        JSTool.twitterTap();
                    }}
                        component={'img'}
                        src={JSTool.getImgPath('twitter.png')}
                        sx={{
                            mr: 1,
                            height: '40rem',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }} />

{/* <Box
                        component={'img'}
                        src={JSTool.getImgPath('facebook.png')}
                        sx={{
                            mr: 1,
                            height: '35rem',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }} /> */}

                    <Box
                        onClick={() => {
                            JSTool.discordTap();
                        }}
                        component={'img'}
                        src={JSTool.getImgPath('discord.png')}
                        sx={{
                            height: '40rem',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.1)',
                            }
                        }} />

                </Stack>
            </Stack>



        </Stack>
    )

}