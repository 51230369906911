import { Stack, Box, Typography } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import { getImgPath } from '../store/JSTool';

export default function TitleView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    return (
        <Stack sx={{
            width: 1,
            alignItems: 'center',
            mt: props.mt,
            zIndex: 1,
        }}>
            <Box
                component={'img'}
                src={getImgPath('title_up2.png')}
                sx={{
                    height: '65rem',
                    display: props.hideUp && 'none',
                }} />

            <Stack sx={{
                flexDirection: 'row',
                height: '70rem',
                position: 'relative',
            }}>
                <Box sx={{
                    width: '250rem',
                    background: 'linear-gradient(90deg, #00000000 , #000000cc)',
                }} />
                <Box sx={{
                    width: '100rem',
                    background: '#000000cc',
                }} />
                <Box sx={{
                    width: '250rem',
                    background: 'linear-gradient(-90deg, #00000000 , #000000cc)',
                }} />
                <Typography
                    id={props.anchorId}
                    sx={{
                        position: 'absolute',
                        width: 1,
                        height: '70rem',
                        lineHeight: '70rem',
                        textAlign: 'center',
                        fontSize: settingData.isMobile ? '42rem' : '45rem',
                        color: 'white',
                        fontFamily: window.MyFont,
                    }}>
                    {props.title}
                </Typography>
            </Stack>

            <Box
                component={'img'}
                src={getImgPath('title_bottom3.png')}
                sx={{
                    height: '65rem',
                }} />
        </Stack>
    );
}