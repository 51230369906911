import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as JSTool from '../store/JSTool';


export default function TermsPage() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {

    }, []);

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            minHeight: settingData.innerHeight,
            alignItems: 'center',
        }}>

            <Stack
                onMouseOver={() => {

                }}
                onMouseLeave={() => {

                }}
                sx={{
                    width: 1,
                    zIndex: 5,
                    position: 'absolute',
                    height: `${settingData.innerHeight}px`,
                    overflow: 'scroll',
                    alignItems: 'center',
                }}>

                <Box
                    component={'div'}
                    sx={{
                        color: '#999999',
                        maxWidth: '1000px',
                        py: 3,
                        mx: 1.5,
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        ${getContent()}
                            `
                    }} />

            </Stack>

        </Stack>
    );
}

function getContent() {
    return `
    <!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8">
            <title>Privacy Policy</title>
              <meta name="format-detection" content="telephone=no" />
              <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no" />
              <style type="text/css">

              .news_body{
                color: #f4f4f4;
            }
            table{
                margin-top: 10px;
                border-collapse: collapse;
                width: 100%;
            }
            td{
                padding: 5px 10px;
            }
            table, td, th{
                border: 1px solid #A6A6A6;
            }
    
            .column-policy {
                width: 90%;
                margin: auto;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 15px;
                line-height: 20px;
                color: #666666;
            }
    
            .title_policy {
                text-align: center;
                font-size: 30px;
                padding-top: 10px;
                display: inline-block;
                width: 100%;
            }
    
            .title-small {
                width: 100%;
                margin-top: 20px;
                font-size: 16px;
                color: #777;
                line-height: 1.2;
                padding-bottom: 5px;
            }
    
            .dot_small_title {
                margin-left: 20px;
                padding: 5px 20px 5px 10px;
                list-style-type: disc;
            }
    
            .title-big {
                width: 100%;
                margin-top: 30px;
                font-size: 20px;
                color: #999;
                line-height: 1.2;
                padding: 0 0 10px 0;
            }
            @media (max-width: 1024px) {
                td{
                    padding: 5px 2px;
                }
                .column-policy {
                    font-size: 14px;
                    width: 96%;
                }
                table{
                    position: relative;
                    left: -1%;
                }
                .wrp{
                    padding-bottom: 0;
                }
            }

       
              </style>
        </head>
        <body>

        <div class="wrp">
		<div class="wrp2">
			<div class="mask"></div>
            <div class="news_show">
                <div class="news_body column-policy" id="privacyDetail">

                    <div class="WordSection1" >

                        <p  align="center" style="margin-bottom:
                        8.0pt;text-align:center;"><span class="title_policy">TERMS
                        OF SERVICE</span><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  align="center" style="margin-bottom:
                        8.0pt;text-align:center;"><span  >Effective
                        Date: December 18, 2022</span><span  style="
                        
                        "><o:p></o:p></span></p>
                        
                        <p  class="title-big"><span ><b><span  style="
                        ">1.</span></b></span><span ><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span ><b><span  style="
                        
                        ">A few important notices—arbitration, refunds, class
                        actions and damages</span></b></span></p>
                        <p  style="
                        "><b><span  style="
                        ">IMPORTANT NOTICE
                        REGARDING ARBITRATION FOR US RESIDENTS: WHEN YOU AGREE TO THIS TERMS OF SERVICE
                        YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU
                        AND US THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE
                        REVIEW CAREFULLY SECTIONS&nbsp;22&nbsp;“DISPUTE RESOLUTION” BELOW FOR DETAILS
                        REGARDING ARBITRATION.&nbsp;</span></b><b><span  style="
                        
                        ">THIS TERMS OF SERVICE ALSO INCLUDES A WAIVER
                        OF RIGHTS BY YOU TO BRING A CLASS ACTION AGAINST US AND A LIMITATION ON DAMAGES
                        THAT YOU CAN COLLECT FROM US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICES. BY
                        USING THE SERVICES, YOU AGREE TO THESE PROVISIONS.</span></b><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><b><span  style="
                        ">IF YOU DO NOT AGREE TO THE TERMS OF THIS
                        TERMS OF SERVICE, YOU MAY NOT INSTALL, COPY, OR USE OUR SERVICES. WE DO NOT
                        NORMALLY OFFER REFUNDS, EXCEPT WHERE EXPRESSLY AUTHORIZED BY US (SEE SECTION 10
                        FOR EXCEPTIONS), OR WHERE REFUNDS ARE REQUIRED UNDER LOCAL LAW.</span></b><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><b><span  style="
                        ">OUR TERMS OF SERVICE MAY ALSO CONTAIN
                        COUNTRY-SPECIFIC ADDENDA THAT APPLY TO YOU BASED ON THE JURISDICTION IN WHICH
                        YOU RESIDE.</span></b><span>&nbsp;<b>To see additional terms applicable to you (which constitute part
                        of this agreement and may supersede these terms), please check the country-specific
                        language on the TERMS OF SERVICE website</b></span><b><span  style="
                        ">.</span></b><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052380"></a><a name="_Toc77497092"></a><a name="_Toc1401366"></a><a name="_Toc19737859"></a><span style="mso-bookmark:_Toc1052380"><b><span  style="
                        
                        ">2.</span></b></span><span style="mso-bookmark:_Toc1052380"><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052380"><b><span  style="
                        
                        ">What is this document? When can I play?</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You are now reading our TERMS OF SERVICE
                        which is a legal agreement between you and&nbsp;</span><span  >A PLUS
                        JAPAN Inc.</span><span  style="
                        ">&nbsp;(“<b>we</b>”,
                        “<b>us</b>”, “<b>our</b>”, as appropriate) regarding the Services you use from
                        us. “<b>Services</b>” mean collectively, and sometimes individually, the
                        following: (a) each of our Games, and (b) any websites, software or other
                        services we provide with or in support of the Game, whether or not they are
                        installed or used on a computer, console, or a mobile device. “<b>Game</b>”
                        means our game that you download and access that is subject to this TERMS OF
                        SERVICE, regardless of where you download and/or access it, and any
                        documentation, software, updates, Virtual Goods and Content (each defined
                        below) included in it.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span>We’ve tried to be
                        straightforward in this TERMS OF SERVICE, and if</span><span  style="
                        ">&nbsp;you have any questions feel free
                        to send us a note at&nbsp;</span><span  >support@sfduelmobile.zendesk.com</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">(our “<b>Support Email
                        Address</b>”)<span style="color:black">.&nbsp; <o:p></o:p></span></span></p>
                        
                        <p  class="title-big"><a name="_Toc77497102"></a><a name="_Toc19737860"></a><a name="_Toc1401367"></a><a name="_Toc1052381"></a><a name="_Toc77497093"></a><a name="_Toc77497094"></a><a name="_Toc77497095"></a><a name="_Toc77497096"></a><a name="_Toc77497097"></a><a name="_Toc77497098"></a><a name="_Toc77497099"></a><a name="_Toc77497100"></a><a name="_Toc77497101"></a><b><span  style="
                        ">3.</span></b><b><span  style="
                        ">&nbsp;&nbsp;</span></b><b><span  style="
                        ">Defined Terms<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You’ll notice some capitalized terms in
                        this TERMS OF SERVICE. They’re called “defined terms,” and we use them so we
                        don’t have to repeat the same language again and again, and to make sure that
                        the use of these terms is consistent throughout the TERMS OF SERVICE.&nbsp;
                        We’ve included the defined terms throughout because we want it to be easy for
                        you to read them in context.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052382"></a><a name="_Toc77497103"></a><a name="_Toc1401368"></a><a name="_Toc19737861"></a><span ><b><span  style="
                        
                        ">4.</span></b></span><span ><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span ><b><span  style="
                        
                        ">Additional Terms</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        
                        ">Some Services may be available (or only available)
                        through accessing (or downloading from) a third-party platform or store,
                        including but not limited to, Facebook, the Epic Games Store, Steam game
                        platform, the Google Play Store and Apple App Store (each, an “<b>App Store</b>”).
                        Your use of the Services is also governed by any applicable agreements you have
                        with any App Store (the “<b>App Store Agreement(s)</b>”).&nbsp;In the event of
                        a conflict between any other App Store Agreement(s) from which you acquire one
                        of our Games and this TERMS OF SERVICE with respect to your use of the
                        Services, this TERMS OF SERVICE will take priority.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        
                        ">The collection of information from you and related to the
                        Services (whether obtained through an App Store or not) is governed by our
                        Privacy Policy at December 18, 2022. Our Privacy Policy explains how we
                        collect, use, and disclose information that we collect from and about you.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052383"></a><a name="_Toc77497104"></a><a name="_Ref67769377"></a><a name="_Toc1401369"></a><a name="_Toc19737862"></a><span style="mso-bookmark:_Toc1052383"><b><span  style="
                        ">5.</span></b></span><span style="mso-bookmark:_Toc1052383"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052383"><b><span  style="
                        
                        ">Eligibility and Registration</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><a name="_Hlk72504646"><span class="index" style="
                        ">(a)</span></a><span style="mso-bookmark:_Hlk72504646"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Hlk72504646"><u><span  style="
                        
                        ">Age</span></u></span><span style="mso-bookmark:_Hlk72504646"></span><span  style="
                        ">.&nbsp;To create
                        a Game Account (as defined below) and access some of our Services, you must be
                        at least the minimum age for consenting to personal data collection under the
                        law in your jurisdiction. If you are between the minimum age for personal data
                        collection and age of majority in your jurisdiction, your parent or guardian
                        must review this TERMS OF SERVICE and accept it on your behalf. Parents and
                        guardians are responsible for the acts of their children under 18 years of age
                        when using our Services.&nbsp;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">We may allow a
                        minor under the minimum age for personal data collection to register for
                        certain Services with the verified consent of a parent or legal guardian. The
                        parent/legal guardian may be asked to provide additional documentation or
                        perform additional actions as part of the verification and approval process as
                        consistent with applicable law. We recommend that parents and guardians
                        familiarize themselves with parental controls on the devices they provide their
                        child.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(b)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Account Creation</span></u><span  style="
                        ">. To access
                        parts of a Game, you may need to create an in-game account (your “<b>Game
                        Account</b>”). Your Game Account, if applicable, is separate from any account
                        you may have with any App Store (your “<b>App Store Account</b>”). You may be
                        able to create your Game Account using an existing account you have with us or
                        your email address. To the extent you create your Game Account through the use
                        of a third-party account (for example, your account with Facebook or Google),
                        we may access certain personal information that this third party provides to us
                        such as your email address and name to help create your Game Account. Further
                        information about use of third-party accounts is provided in the&nbsp;Privacy
                        Policy at </span><span  style="
                       ">December 18, 2022</span><span  style="
                        ">.&nbsp;Please note that you may also be
                        able to play the Game without creating a Game Account, but you may not be able
                        to access certain parts of the Game, and your Game data may be deleted if you
                        uninstall or otherwise delete the Game.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(c)</span><span  style="
                        "></span><u><span  style="
                        ">Keep Your
                        Information Current</span></u><span  style="
                        
                        ">. It’s important that you provide us with accurate,
                        complete and up-to-date information for your Game Account and you agree to update
                        such information to keep it that way. If you don’t, we may suspend or terminate
                        your Game Account. You agree that you will not disclose your Game Account
                        password to anyone and will notify us immediately of any unauthorized use of
                        your Game Account. You are responsible for all activities that occur under your
                        Game Account, whether or not you know about them.&nbsp;&nbsp;If you believe
                        that your Game Account is no longer secure, then you must immediately notify us
                        at our Support Email Address.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(d)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">No Account
                        Sharing.</span></u><span  style="
                        ">&nbsp;&nbsp;You
                        may not sell, resell, rent, lease, share or provide access to your Game Account
                        to anyone else. We reserve all available legal rights and remedies to prevent
                        unauthorized use of our Services, including, but not limited to, technological
                        barriers, IP mapping, and, in serious cases, directly contacting your Internet
                        Service Provider (ISP) regarding such unauthorized use.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(e)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">No False
                        Accounts.</span></u><span  style="
                        ">&nbsp;You may
                        not create a Game Account for anyone else or create a Game Account in a name
                        other than your own.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref526361970"></a><a name="_Toc77497105"></a><a name="_Ref67769277"></a><a name="_Toc1401370"></a><a name="_Toc19737863"></a><a name="_Toc1052384"></a><span style="mso-bookmark:
                        _Ref526361970"><b><span  style="
                        ">6.</span></b></span><span style="mso-bookmark:_Ref526361970"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref526361970"><b><span  style="
                        
                        ">License</span></b></span><b><span  style="
                        ">&nbsp;and Use of the Services<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><a name="_Ref67769529"><span  class="index" style="
                        ">(a)</span></a><span style="mso-bookmark:_Ref67769529"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769529"><u><span  style="
                        
                        ">License Grant</span></u></span><span style="mso-bookmark:
                        _Ref67769529"><span  style="
                        ">. So long as you
                        comply with this TERMS OF SERVICE and, as applicable, the App Store
                        Agreement(s), we grant you the following license: a personal, non-exclusive,
                        non-transferable, non-sublicensable limited right and license to (i) download
                        and install the Game on the device permitted by the App Store Agreement(s) (if
                        applicable), and (ii) access and use the Services, including any Content,
                        for&nbsp;your personal entertainment purposes leveraging only the functionality
                        of the Game and Services. We and our licensors reserve all rights not granted
                        to you in this TERMS OF SERVICE.&nbsp;</span></span><span  style="
                        ">“<b>Content</b>” means all artwork,
                        titles, themes, objects, characters, names, dialogue, catch phrases, stories,
                        animation, concepts, sounds, audio-visual effects, methods of operation,
                        musical compositions, Virtual Goods (defined in Section&nbsp;9) and any other
                        content within the Services. Content also includes anything generated, created,
                        or that is otherwise developed within the Services by any user (including you)
                        as a result of interaction with the functionality of the Services. We also
                        grant you a limited license to make gameplay videos for certain Games,
                        specified on the Services provided that you agree that all such gameplay video
                        activity is subject to your agreement to and pursuant to our Streaming Policy
                        in Schedule C-1.&nbsp; We may, in our sole discretion, remove, edit or disable
                        any Content for any reason.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769534"><span class="index" style="
                        ">(b)</span></a><span style="mso-bookmark:_Ref67769534"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769534"><u><span  style="
                        
                        ">Content You Create Outside the Services</span></u></span><span style="mso-bookmark:_Ref67769534"><span  style="
                        
                        ">.</span></span><span style="mso-bookmark:_Ref67769534"></span><span  >&nbsp;</span><span  style="
                        ">“<b>User Content</b>”
                        means any Content that you (or other Game Account holders) create or obtain
                        outside the Services that you or another user makes available within the
                        Services. To be clear, if Content is created within the Services, it is&nbsp;<u>not</u>&nbsp;User
                        Content; only Content created or obtained from&nbsp;<u>outside</u>&nbsp;the
                        Services that a user then makes available within the Services is User Content.
                        By making any User Content available through the Services you hereby grant to
                        us the following license: a non-exclusive, transferable, worldwide,
                        royalty-free license, with the right to sublicense, to use, copy, modify,
                        create derivative works based upon, distribute, publicly display, and publicly
                        perform your User Content in connection with operating and providing the
                        Services and Content to you and to other users of the Services. Except to the
                        extent prohibited by law, you waive the benefit of any “moral rights” or “droit
                        moral” or similar rights in any country to any User Content.&nbsp; We may, in
                        our sole discretion, remove, edit or disable any User Content at any time and
                        for any reason, including if we determine that the User Content violates this TERMS
                        OF SERVICE. We do not assume any responsibility or liability for User Content,
                        for removing it, or not removing it or other Content. We do not pre-screen or
                        review any User Content and do not approve or endorse any User Content that may
                        be available on the Services or our other services.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(c)</span><span  style="
                        "></span><u><span  style="
                        ">Service Limits
                        Based on Where You Live</span></u><span  style="
                        
                        ">.<i>&nbsp;</i>We may restrict, modify, or limit your
                        access to and use of certain Content, Virtual Goods, an entire Game, or any or
                        all of the Services depending on the territory in which you are located. Without
                        limiting the foregoing,&nbsp;Content, Virtual Goods, entire&nbsp;Games, or the
                        Services&nbsp;may not be available (in&nbsp;whole or in part) where&nbsp;you
                        are located&nbsp;or may only be available in a modified version, if they do not
                        comply with the laws which apply in your country.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769387"><span class="index" style="
                        ">(d)</span></a><span style="mso-bookmark:_Ref67769387"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769387"><u><span  style="
                        
                        ">Retail Purchase</span></u></span><span style="mso-bookmark:
                        _Ref67769387"></span><i><span >.&nbsp;</span></i><span>We may offer codes or product keys that can be activated in a Game or used
                        to activate a Game on the App Store.&nbsp;Subject to foreign exchange control
                        regulations applicable in your jurisdiction,&nbsp;such codes or keys must be
                        purchased (or otherwise obtained legally) through us or one of our authorized
                        retailers to be valid. If you purchase such a code or key from a third party,
                        that third party is responsible for addressing any issues you have with these
                        codes or keys. Subject to applicable law, we will have no responsibility for
                        these codes or keys purchased from any third party&nbsp;or if any purchase
                        occurred in breach with any applicable foreign exchange control regulations.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(e)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Minimum
                        Requirements</span></u><span  >. The Game may have minimum requirements for the devices and systems on
                        which you wish to play the Game. We may publish these minimum requirements on
                        the applicable website and/or otherwise notify you in writing. For an optimal
                        experience, please ensure that your devices and systems will meet these
                        requirements before playing the Game.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(f)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Seizure Warning</span></u><span  style="
                        ">. The Game may
                        contain flashing lights, images, and other luminous stimulations which may
                        induce epileptic seizures in certain individuals. If you or anyone in your
                        household has an epileptic condition, please consult your doctor before playing
                        any Game. If you experience dizziness, altered vision, eye or muscle twitches,
                        loss of awareness, disorientation, any involuntary movement, or convulsions while
                        playing, please immediately discontinue playing the Game and consult your
                        doctor.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref67769076"></a><a name="_Toc77497106"></a><a name="_Ref67769169"></a><span style="mso-bookmark:
                        _Ref67769076"><b><span  style="
                        ">7.</span></b></span><span style="mso-bookmark:_Ref67769076"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref67769076"><b><span  style="
                        
                        ">Player Conduct</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You agree not to do any of the following
                        with respect to the Services, as determined by us, as applicable:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  class="index" style="
                        ">(a)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">use them
                        commercially, for a promotional purpose, or for the benefit of any third party
                        or in any manner not permitted by this TERMS OF SERVICE;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(b)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">use, or provide,
                        any unauthorized third-party programs that intercept, emulate, or redirect any
                        communication between the Services and us or that collect information about the
                        Game;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(c)</span><span  style="
                        "></span><span  style="
                        ">use, or provide
                        ancillary offerings to anyone, that are not offered within the Services by us
                        (or the functionality of the App Store), such as hosting, “leveling” services,
                        mirroring our servers, matchmaking, emulation, communication redirects, mods,
                        hacks, cheats, bots (or any other automated control), trainers and automation
                        programs that interact with the Services in any way, tunneling, third party
                        program add-ons, and any interference with online or network play;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(d)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">access or use
                        them on more than one device at a time;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(e)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">copy, reproduce,
                        distribute, display, mirror, frame or use them (or any of our other materials,
                        intellectual property, or proprietary information) in a way that is not
                        expressly authorized in this TERMS OF SERVICE;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(f)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">sell, rent,
                        lease, license, distribute, or otherwise transfer the Services, Game or any
                        Content, including, without limitation, Virtual Goods or Game Currency,
                        including participating in or operating so called “secondary markets” for
                        Virtual Goods, Game Currency or Content;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(g)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">attempt to
                        reverse engineer (except as otherwise permitted by applicable local law),
                        derive source code from, modify, adapt, translate, datamine, decompile, or
                        disassemble or make derivative works based upon the Services or any Content;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(h)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">remove, disable,
                        circumvent, or modify any technological measure we implement to protect them or
                        any of their associated intellectual property;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(i)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">create, develop,
                        distribute, or use any unauthorized software programs to gain advantage in any
                        online or other game modes or otherwise Cheat (as defined below);<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(j)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">attempt to
                        probe, scan or test its vulnerability or breach any security or authentication
                        measures;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(k)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">access, tamper
                        with, or use non-public areas of the Services;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(l)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">trespass, or
                        attempt to gain access to a property or location where you do not have
                        permission to be or engage in any activity that may result in injury, death,
                        property damage, nuisance or other liability;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(m)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">upload, publish,
                        submit or transmit any User Content, create a user name or account name, or
                        otherwise engage in any behavior that: (i) infringes, misappropriates or
                        violates a third party’s patent, copyright, trademark, trade secret,
                        contractual rights, moral rights or other intellectual property rights, or
                        rights of publicity or privacy, or any other rights of third parties; (ii)
                        violates, or encourages any conduct that would violate any applicable law or
                        regulation or would give rise to civil liability; (iii) is fraudulent, false,
                        misleading or deceptive; (iv) is grossly harmful, racially or ethnically
                        objectionable, disparaging, blasphemous, libelous, defamatory, obscene,
                        pornographic,&nbsp;</span><span >paedophilic</span><span  style="
                        ">, vulgar or offensive; (v) promotes
                        discrimination, bigotry, racism, hatred, harassment or harm against any
                        individual or group; (vi) is violent, bullying, or threatening or promotes
                        violence, money laundering or gambling, terrorism, or actions that are
                        threatening or disrespectful to any person or entity; (vii) harms minors in any
                        way; or (viii) promotes illegal or harmful activities or substances;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(n)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">engage in any
                        behavior that: (i) violates, or encourages any conduct that would violate any
                        applicable law or regulation or would give rise to civil liability; (ii) is
                        fraudulent, false, misleading or deceptive, including “trolling;” (iii) is
                        defamatory, obscene, pornographic, vulgar or offensive; (iv) promotes
                        discrimination, bigotry, racism, hatred, harassment or harm against any
                        individual or group; (v) is disruptive to the Game, App, its users or user
                        community,&nbsp; is violent, bullying, or threatening or promotes violence,
                        terrorism, or actions that are threatening or disrespectful to any person or
                        entity; or (vi) promotes illegal or harmful activities or substances;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(o)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">interfere with,
                        or attempt to interfere with, the access of any user, host or network,
                        including, without limitation, sending a virus, overloading, flooding,
                        spamming, or mail-bombing the Services or any of its users;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(p)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">collect or store
                        any information that could be used to identify an individual, either itself or
                        combined with other information, from the Services from other users of the
                        Services without their express permission;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(q)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">behave in a
                        manner which is detrimental to the enjoyment of the Services by other users as
                        intended by us, in our sole judgment, including, without limitation,
                        harassment, use of abusive or offensive language, game abandonment, game
                        sabotage, spamming, behaving in a disruptive manner, social engineering, or
                        scamming, or contrary to public morals or public policy;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(r)</span><span  style="
                        "></span><span  style="
                        ">impersonate or
                        misrepresent your affiliation with any person or entity, or deceives or
                        misleads the addressee about the origin of such messages or communicates any
                        information which is grossly offensive or menacing in nature;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(s)</span><span  style="
                        "></span><span  style="
                        ">use IP proxying
                        or other methods to disguise the place of your residence, whether to circumvent
                        geographical restrictions on the Services or for any other purpose;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(t)</span><span  style="
                        "></span><span  style="
                        ">play on another
                        person’s Game Account to “boost” that Game Account’s status, level or rank;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(u)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">use the Services
                        in any way that would affect us adversely or reflect negatively on us or the Services
                        or discourage any person from using all or any portion of the features of the
                        Services;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(v)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">encourage,
                        promote, take part in or enable anyone else to do any of the foregoing; or<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(w)</span><span  style="
                        ">&nbsp;&nbsp;</span><span  style="
                        ">violate any
                        applicable law or regulation.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">If you encounter another user who is
                        violating any of these rules, please report this activity to us using the
                        “Report Abuse” function in the relevant Game or part of the Service, if
                        available, or contact us at </span><span  >support@sfduelmobile.zendesk.com</span><span  style="
                        ">.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052386"></a><a name="_Ref527537021"></a><a name="_Ref526352598"></a><a name="_Toc77497107"></a><a name="_Toc1401372"></a><a name="_Toc19737865"></a><a name="_Ref1051796"></a><span style="mso-bookmark:_Toc1052386"><b><span  style="
                        
                        ">8.</span></b></span><span style="mso-bookmark:_Toc1052386"><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052386"><b><span  style="
                        
                        ">Ownership</span></b></span><b><span  style="
                        ">&nbsp;of the Services<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">We and our affiliates and licensors own
                        all title, ownership and intellectual property rights in the Services. You
                        agree not to remove, alter or obscure any copyright, trademark, service mark or
                        other proprietary rights notices incorporated in or accompanying the
                        Services.&nbsp; You understand and agree that you have no ownership interest in
                        the Services or any Games or Content therein.&nbsp;The Services (and
                        particularly our Games) may have built-in mechanisms designed to prevent
                        granting one user an unfair advantage over other users (these actions are “<b>Cheating</b>”
                        and the software is the “<b>Cheat Detection Software</b>”). We may add or
                        update our Cheat Detection Software periodically as we may require in our sole
                        discretion. The Services and/or the Cheat Detection Software may collect and
                        transmit details about your Game Account, gameplay, and unauthorized programs
                        or processes in connection with Cheating, subject to our Privacy Policy and
                        applicable law. In the event that we in our sole discretion conclude that you
                        are Cheating, you agree that we may exercise any or all of our rights under
                        this TERMS OF SERVICE, including termination of this TERMS OF SERVICE and your
                        access to our Services. In addition, if you Cheat in one Game or Service, we
                        may terminate your license to use all of our Games and Services.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Although we are not obligated to monitor
                        access to or use of the Services or to review or edit any Content, we have the
                        right to do so for the purpose of operating and publishing the Services, to
                        ensure compliance with this TERMS OF SERVICE, to protect the health or safety
                        of anyone that we believe may be threatened, to protect our legal rights and
                        remedies, to report a crime or offensive behavior, or to comply with applicable
                        law. We may (but don’t have to) remove or disable access to any Content, at any
                        time and without notice. We may (but don’t have to) investigate violations of
                        this TERMS OF SERVICE or conduct that affects the Services.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref1401265"></a><a name="_Toc77497108"></a><a name="_Toc1401373"></a><a name="_Toc19737866"></a><a name="_Toc1052387"></a><span style="mso-bookmark:_Ref1401265"><b><span  style="
                        ">9.</span></b></span><span style="mso-bookmark:_Ref1401265"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref1401265"><b><span  style="
                        
                        ">Virtual Goods and Game</span></b></span><b><span  style="
                        ">&nbsp;Currency<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  class="index" style="
                        ">(a)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Purchasing or
                        Obtaining Virtual Goods and Game Currency</span></u><span  style="
                        ">. Subject to applicable foreign exchange
                        control regulations in your jurisdiction, we may offer certain upgrades and
                        options within our Games that you can buy with real world currency, including,
                        but not limited to, in-game currency where permitted under applicable law (“<b>Game
                        Currency</b>”), character skins, mounts and vehicles, digital cards, experience
                        boosts, gear and other customizations for your in-Game characters, and other
                        such digital add-on items that may improve your Game experience in some way (“<b>Virtual
                        Goods</b>”). Except as otherwise communicated to you within the functionality
                        of the Game, Game Currency and Virtual Goods are not transferable from one Game
                        to another. You may also be able to obtain certain Virtual Goods and Game
                        Currency without making a purchase, such as an in-Game award. When you purchase
                        Game Currency, Virtual Goods, or a Game itself (each, a “<b>Transaction</b>”),
                        your purchase will be made through the functionality available through the App
                        Store or other platforms we make available to you. Prior to making a
                        Transaction, you should make sure you fully understand the agreement that
                        covers your Transaction, whether that agreement is an App Store Agreement or
                        another payment platform agreement<span style="mso-spacerun:yes">&nbsp;
                        </span>There may be limits to the quantity and number of times you can purchase
                        Virtual Goods, Game Currency, or other aspects of your Transaction. For
                        example, there may be a maximum amount of Game Currency you are able to hold
                        (in-Game) or purchase at a given time, or a maximum number of Transactions you
                        may make per day; these additional restrictions may be communicated to you via
                        the functionality of the Services. We may, from time to time, modify, amend, or
                        supplement our fees, billing methods and terms applicable to Game Currency,
                        Virtual Goods or to any purchases, and post those changes in this TERMS OF
                        SERVICE, in separate terms and conditions or in other terms or agreements
                        posted on the applicable website or as part of the Game or otherwise provided
                        to you by us. Except where prohibited by law in your jurisdiction, such
                        modifications, amendments, supplements or terms shall be effective immediately
                        upon posting and shall be incorporated by reference into this TERMS OF SERVICE.
                        If any change is unacceptable to you, you may terminate the use of your Game
                        Account at any time.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769540"><span class="index" style="
                        ">(b)</span></a><span style="mso-bookmark:_Ref67769540"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769540"><u><span  style="
                        
                        ">Your License to Virtual Goods and Game Currency</span></u></span><span style="mso-bookmark:_Ref67769540"></span><span  style="
                        
                        ">. Virtual Goods and Game Currency are digital
                        items and your use of them is governed by this TERMS OF SERVICE and the App
                        Store Agreement(s).&nbsp; VIRTUAL GOODS AND GAME CURRENCY HAVE NO MONETARY
                        VALUE AND CANNOT BE REDEEMED FOR CASH. VIRTUAL GOODS AND GAME CURRENCY ARE
                        NON-TRANSFERABLE AND NON-TRADABLE, IN WHOLE OR PART, AND ARE NOT PROVIDED FOR
                        INVESTMENT PURPOSES.&nbsp;Virtual Goods and Game Currency are licensed, not
                        sold.&nbsp;Provided you comply with the terms of this TERMS OF SERVICE and the
                        App Store Agreement(s), we grant you the following license: a personal,
                        non-exclusive, non-transferable, non-sublicensable limited right and license to
                        use any Virtual Goods or Game Currency you gain access to, whether purchased or
                        otherwise obtained, solely in connection with your use of the Game&nbsp;in
                        question&nbsp;and within the Game (unless we otherwise communicate to you that
                        you may use them in multiple Games) and for no other purpose.&nbsp; Unless,
                        expressly permitted by us in a specific Game, you may not trade any such
                        Virtual Good or Game Currency with others. We may cancel, revoke, or otherwise
                        prevent the use of Virtual Good or Game Currency if we suspect any unauthorized
                        or fraudulent activity, and/or to correct any erroneous application of any
                        Virtual Goods or Game Currency to your Account.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769194"><span class="index" style="
                        ">(c)</span></a><span style="mso-bookmark:_Ref67769194"><span  style="
                        
                        "></span></span><span style="mso-bookmark:_Ref67769194"><u><span  style="
                        
                        ">Changes to Game Currency and Virtual Goods.</span></u></span><span style="mso-bookmark:_Ref67769194"></span><span  style="
                        
                        ">&nbsp;Except as otherwise prohibited by
                        applicable law, we, in our sole discretion, may modify, substitute, replace,
                        suspend, cancel or eliminate any Game Currency or Virtual Goods, including your
                        ability to access or use Game Currency or Virtual Goods, without notice or
                        liability to you, such as if we need to temporarily suspend the Game to make
                        updates, have an emergency that requires us to disable our Services, or if we
                        need to ultimately shut a Game down for economic or other reasons due to a
                        limited number of users continuing to make use of the online Service over time.
                        YOU AGREE THAT YOU WON’T ASSERT OR BRING ANY CLAIM AGAINST US, OUR AFFILIATES,
                        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR LICENSORS (THE “<b>COMPANY PARTIES</b>”)
                        RELATING TO (A) A CLAIM THAT YOU HAVE A PROPRIETARY INTEREST IN ANY GAME
                        CURRENCY OR VIRTUAL GOODS; OR (B) A CLAIM FOR AN ALLEGED MONETARY VALUE OF GAME
                        CURRENCY OR VIRTUAL GOODS LOST UPON (I) DELETION OR SUSPENSION OF YOUR ACCOUNT,
                        (II) ADJUSTMENTS TO THE GAME THAT RESULT IN THE VALUE OF VIRTUAL GOODS OR GAME
                        CURRENCY CHANGING, OR (III) MODIFICATION, TERMINATION OR EXPIRATION OF THIS TERMS
                        OF SERVICE.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref530935558"></a><a name="_Toc77497109"></a><a name="_Toc1401374"></a><a name="_Toc19737867"></a><a name="_Toc1052388"></a><span style="mso-bookmark:_Ref530935558"><b><span  style="
                        ">10.</span></b></span><span style="mso-bookmark:_Ref530935558"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref530935558"><b><span  style="
                        
                        ">Refunds</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">Subject to applicable law (including as
                        described in each country-specific addendum below), or App Store policy, (i)
                        all Games, Virtual Goods and Game Currency remain our property, have no
                        monetary value and are not redeemable, refundable, or eligible for any other
                        alternate remedy for any “real world” money or anything of monetary value,
                        unless they are defective, unavailable, or do not perform in accordance with
                        the specifications we provide; (ii) we may revoke your license to such Games,
                        Virtual Goods and/or Game Currency at any time consistent with this TERMS OF
                        SERVICE without notice or liability to you; and (iii) except where the law in
                        your jurisdiction provides a right of withdrawal that cannot be waived by
                        contract,&nbsp;by purchasing and using any Games, Virtual Goods and/or Game
                        Currency, you hereby waive your right to withdraw from your agreement to
                        purchase the applicable Game, Virtual and/or Game Currency and you agree that
                        you will therefore not be eligible to receive a refund (or any alternative
                        remedy) in relation to such Game, Virtual Good and/or Game Currency.
                        Additionally, you hereby agree that any withdrawal right expires immediately
                        upon purchase and delivery of your Game, Virtual Good and/or Game Currency,
                        unless the law in your jurisdiction provides otherwise. This section does not
                        affect your statutory rights.&nbsp; &nbsp;<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052389"></a><a name="_Ref498960534"></a><a name="_Toc77497110"></a><a name="_Ref67769297"></a><a name="_Toc1401375"></a><a name="_Toc19737868"></a><span style="mso-bookmark:
                        _Toc1052389"><b><span  style="
                        ">11.</span></b></span><span style="mso-bookmark:_Toc1052389"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052389"><b><span  style="
                        
                        ">Beta Testing</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">From time to time we may offer a beta
                        version of one of our Services (a “<b>Beta</b>”). As the name implies, Betas
                        are not commercial launch versions, are not guaranteed to work properly, and
                        may make other parts of your system not work properly as well. For the license
                        granted to you in Section&nbsp;6&nbsp;above to extend to the Beta (meaning, for
                        you to have permission to use the Beta), you acknowledge and agree to the
                        following terms in addition to the rest of this TERMS OF SERVICE:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(a)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">We may
                        automatically delete or modify the information stored on your computer related
                        to the Beta for any reason at any time during the duration of the Beta test;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(b)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">We may terminate
                        the Beta test at any time, which would then render your Beta unplayable or
                        unable to function properly. When we terminate a Beta, you must delete the
                        local Beta instance on your computer and all documents and materials you
                        received from us in connection with the Beta;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(c)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">Use of a Beta is
                        subject to confidential treatment of that Beta and all elements thereof.
                        "Confidential Information" means any information disclosed by us to
                        you, or accessed or provided by you, in relation to the Beta (including any
                        feedback provided and the Game itself). You agree that you will:<o:p></o:p></span></p>
                        
                        <p  style="
                        text-indent:20.0pt;;mso-outline-level:
                        3"><span  style="
                        ">(i)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">not use any
                        Confidential Information other than as necessary to use the Beta in accordance
                        with this TERMS OF SERVICE;<o:p></o:p></span></p>
                        
                        <p  style="
                        text-indent:20.0pt;;mso-outline-level:
                        3"><span  style="
                        ">(ii)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">maintain
                        Confidential Information in strict confidence and use the same degree of care
                        to protect it as you use to protect your own confidential information, but in
                        no circumstances less than reasonable care;<o:p></o:p></span></p>
                        
                        <p  style="
                        text-indent:20.0pt;;mso-outline-level:
                        3"><span  style="
                        ">(iii)</span><span  style="
                        ">&nbsp;&nbsp;</span><span  style="
                        ">not disclose the
                        Confidential Information to any person or entity other than as permitted by us;
                        and<o:p></o:p></span></p>
                        
                        <p  style="
                        text-indent:20.0pt;;mso-outline-level:
                        3"><span  style="
                        ">(iv)</span><span  style="
                        ">&nbsp;&nbsp;</span><span  style="
                        ">not make any
                        public announcements related to Beta or the Service, including publishing or
                        disclosing any information (e.g. screenshots and specifications) relating to
                        the Beta, without our prior written approval, which we may grant or withhold in
                        our sole discretion.&nbsp;<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(d)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">Termination of a
                        Beta by us is not grounds for any kind of refund and your participation in a
                        Beta does not entitle you to any compensation or any free Services, including
                        any Content and Game Currency; and<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref19285649"><span  style="
                        ">If and when we
                        release a full (non-Beta) version of the particular Game and we may allow your
                        use of the Game to continue to the full version. If so allowed by us, your
                        continued use of the Game will no longer be subject to this Section&nbsp;</span></a><span  style="
                        ">11&nbsp;but will
                        still be subject to the rest of this TERMS OF SERVICE.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref524372603"></a><a name="_Toc77497111"></a><a name="_Ref67769303"></a><a name="_Toc1401376"></a><a name="_Toc19737869"></a><a name="_Toc1052390"></a><span style="mso-bookmark:
                        _Ref524372603"><b><span  style="
                        ">12.</span></b></span><span style="mso-bookmark:_Ref524372603"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref524372603"><b><span  style="
                        
                        ">App Store</span></b></span><b><span  style="
                        ">; Console Games<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">Where a Game is made available to you
                        via an App Store (whether on your mobile device or console) you acknowledge and
                        agree to the terms in Schedule B-1 with respect to such Game.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc77497116"></a><a name="_Toc1401377"></a><a name="_Toc19737870"></a><a name="_Toc1052391"></a><a name="_Ref526358605"></a><a name="_Toc77497112"></a><a name="_Toc77497113"></a><a name="_Toc77497114"></a><a name="_Toc77497115"></a><b><span  style="
                        ">13.</span></b><b><span  style="
                        ">&nbsp;&nbsp;</span></b><b><span  style="
                        ">Feedback<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">We welcome your feedback, comments and
                        suggestions for improvements to the Services (“<b>Feedback</b>”). You can
                        submit Feedback by emailing us at </span><span  style="
                       ">support@sfduelmobile.zendesk.com</span><span  style="
                        "> or via the
                        functionality of the Services (if available). If you provide us with any
                        Feedback, you hereby grant us the following license: a non-exclusive,
                        transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free
                        license, with the right to sublicense, under any and all intellectual property
                        rights that you own or control to use, copy, modify, create derivative works
                        based upon, distribute copies of, publicly perform, publicly display and
                        otherwise exploit the Feedback for any purpose and in any country. This license
                        does not lapse or expire even if we do not exercise our rights under this
                        license within a period of one year. If you have rights in the Feedback that
                        cannot be licensed to us under applicable law (such as moral and other personal
                        rights), you hereby waive and agree not to assert those rights. You understand
                        and agree that you are freely giving your Feedback, that we don’t have to use
                        it, and that you will not be compensated in any way for your Feedback. You
                        represent and warrant that you have rights in any Feedback that you provide to
                        us sufficient to grant us and other affected parties the rights described
                        above, including, without limitation, intellectual property rights or rights of
                        publicity or privacy.&nbsp; &nbsp;<a name="_Toc1401379"></a><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">In posting such Feedback, you warrant
                        that your feedback is in compliance with this TERMS OF SERVICE and you will not
                        use obscene or offensive language or submit any material that is, or may
                        potentially be, defamatory, abusive or hateful, an invasion of anyone's
                        privacy, harmful to other users, or in breach of any applicable
                        law.&nbsp;&nbsp;<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc19737871"></a><a name="_Toc77497117"></a><span style="mso-bookmark:_Toc19737871"><b><span  style="
                        ">14.</span></b></span><span style="mso-bookmark:_Toc19737871"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc19737871"><b><span  style="
                        
                        ">DMCA/Copyright Policy</span></b></span><b><span  style="
                        ">&nbsp;<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">We respect copyright law and expect our
                        users to do the same. It’s our policy to terminate in appropriate circumstances
                        Game Accounts of users who repeatedly infringe or are believed to be repeatedly
                        infringing the rights of copyright holders.<o:p></o:p></span></p>
                        
                        <p class="title-big"><a name="_Toc1052393"></a><a name="_Toc77497118"></a><a name="_Toc1401380"></a><a name="_Toc19737872"></a><a name="_Ref19286516"></a><span style="mso-bookmark:_Toc1052393"><b><span  style="
                        ">15.</span></b></span><span style="mso-bookmark:_Toc1052393"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052393"><b><span  style="
                        
                        ">Third-Party Websites and Resources</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">The Services may contain links to
                        third-party websites or resources. We provide these links only as a convenience
                        and are not responsible for the content, products or services on or available
                        from those websites or resources or links displayed on such websites. You
                        acknowledge sole responsibility for and assume all risk arising from, your use
                        of any third-party websites or resources.<a name="_Ref523929224"></a><o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052394"></a><a name="_Ref526360052"></a><a name="_Toc77497119"></a><a name="_Toc1401381"></a><a name="_Toc19737873"></a><span style="mso-bookmark:_Toc1052394"><b><span  style="
                        ">16.</span></b></span><span style="mso-bookmark:_Toc1052394"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052394"><b><span  style="
                        
                        ">Data Charges and Mobile Devices</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You are responsible for all data-related
                        charges that you may incur for using our Services, including, without
                        limitation, mobile, text-messaging, and data charges. You should understand or
                        ask your service provider what charges you may incur before using the Services.<o:p></o:p></span></p>
                        
                        <p class="title-big"><a name="_Ref1401740"></a><a name="_Toc77497120"></a><a name="_Toc1401382"></a><a name="_Toc19737874"></a><a name="_Toc1052395"></a><span style="mso-bookmark:_Ref1401740"><b><span  style="
                        ">17.</span></b></span><span style="mso-bookmark:_Ref1401740"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref1401740"><b><span  style="
                        
                        ">Service and Modifications</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">We may (but don’t have to) update this TERMS
                        OF SERVICE at any time whenever we think there is a need. Subject to applicable
                        law, if we do so, you will be prompted to agree to the updated TERMS OF SERVICE
                        upon your next access to the Services or when the updated TERMS OF SERVICE is
                        otherwise communicated to you. You must agree to these updates to continue
                        using the Services.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">We may provide patches, updates, or
                        upgrades to the Services that must be installed in order for you to continue to
                        use the Services. We may update the Services remotely without notifying you,
                        and you hereby consent to us applying such patches, updates, and upgrades. If
                        your device can prevent automatic updates, you may not be able to access the
                        Services until you manually update the Services yourself on your device. We may
                        modify, suspend, discontinue, substitute, replace, or limit your access to any
                        aspect of the Services at any time. Subject to applicable law, you acknowledge
                        that any character data, game progress, game customization or other data
                        related to your use of any particular Game and other elements unique to the
                        Services may cease to be available to you at any time without notice from us,
                        including, without limitation, after a patch, update, or upgrade is applied by
                        us. You agree that we do not have any maintenance or support obligations with
                        respect to the Services.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Subject to applicable law, we may change
                        the price of the Services, Games, Virtual Goods, Game Currency or Content, at
                        any time, for any reason, without notice or liability to you.<o:p></o:p></span></p>
                        
                        <p class="title-big"><a name="_Toc1052396"></a><a name="_Toc77497121"></a><a name="_Ref67769420"></a><a name="_Ref67769328"></a><a name="_Ref67769312"></a><a name="_Ref67769266"></a><a name="_Ref67769249"></a><a name="_Toc1401383"></a><a name="_Toc19737875"></a><a name="_Ref19286519"></a><span style="mso-bookmark:_Toc1052396"><b><span  style="
                        
                        ">18.</span></b></span><span style="mso-bookmark:_Toc1052396"><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052396"><b><span  style="
                        
                        ">Warranty Disclaimers</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">TO THE MAXIMUM EXTENT PERMITTED BY LAW
                        THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING
                        THE FOREGOING, THE COMPANY PARTIES EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES
                        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND
                        NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE
                        OF TRADE. The Company Parties make no warranty that the Services will meet your
                        requirements or be available on an uninterrupted, secure, or error-free basis.
                        The Company Parties make no warranty regarding the quality, accuracy,
                        timeliness, truthfulness, completeness or reliability of the Services.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref523929225"></a><a name="_Toc77497122"></a><a name="_Toc1401384"></a><a name="_Toc19737876"></a><a name="_Toc1052397"></a><span style="mso-bookmark:_Ref523929225"><b><span  style="
                        ">19.</span></b></span><span style="mso-bookmark:_Ref523929225"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref523929225"><b><span  style="
                        
                        ">Limitation of Liability</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW THE COMPANY PARTIES WILL NOT BE LIABLE IN ANY WAY FOR ANY:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769437"><span  class="index" style="
                        ">(a)</span></a><span style="mso-bookmark:_Ref67769437"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769437"><span  style="
                        
                        ">LOSS OF PROFITS,</span></span><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(b)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">LOST REVENUE,<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769568"><span class="index" style="
                        ">(c)</span></a><span style="mso-bookmark:_Ref67769568"><span  style="
                        
                        "></span></span><span style="mso-bookmark:_Ref67769568"><span  style="
                        
                        ">LOST SAVINGS,</span></span><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(d)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">LOSS OF DATA, OR<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(e)</span><span  style="
                        ">&nbsp;</span><span  style="
                        ">ANY INDIRECT,
                        INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES,<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">ARISING OUT OF OR IN CONNECTION WITH
                        THIS TERMS OF SERVICE OR THE SERVICES, OR THE DELAY OR INABILITY TO USE OR LACK
                        OF FUNCTIONALITY OF THE SERVICES, EVEN IN THE EVENT OF ONE OF A COMPANY PARTY’S
                        FAULT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, INDEMNITY, PRODUCT
                        LIABILITY, BREACH OF CONTRACT, BREACH OF WARRANTY, OR OTHERWISE AND EVEN IF
                        THAT COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;
                        FURTHER, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE
                        LIABILITY OF THE COMPANY PARTIES ARISING OUT OF OR IN CONNECTION WITH THIS TERMS
                        OF SERVICE OR THE SERVICES WILL NOT EXCEED: (A) THE TOTAL AMOUNTS YOU HAVE PAID
                        (IF ANY) OR ARE PAYABLE BY YOU TO US FOR THE PARTICULAR GAME OR SERVICE IN
                        QUESTION DURING THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING RISE
                        TO SUCH LIABILITY; OR (B) IF (A) DOES NOT APPLY, FIFTY DOLLARS ($50 USD). THESE
                        LIMITATIONS AND EXCLUSIONS REGARDING DAMAGES APPLY EVEN IF ANY REMEDY FAILS TO
                        PROVIDE ADEQUATE COMPENSATION AND ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
                        BARGAIN BETWEEN YOU AND US.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Notwithstanding the foregoing, some countries,
                        states, provinces or other jurisdictions do not allow the exclusion of certain
                        warranties or the limitation of liability as stated above, so the above terms
                        may not apply to you. Instead, in such jurisdictions, the foregoing exclusions
                        and limitations will apply to the maximum extent permitted by the laws of such
                        jurisdictions. Also, you may have additional legal rights in your jurisdiction,
                        and nothing in this TERMS OF SERVICE will prejudice such rights that you may
                        have as a consumer of the Services.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref523929229"></a><a name="_Toc77497123"></a><a name="_Toc1401385"></a><a name="_Toc19737877"></a><a name="_Toc1052398"></a><span style="mso-bookmark:_Ref523929229"><b><span  style="
                        ">20.</span></b></span><span style="mso-bookmark:_Ref523929229"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref523929229"><b><span  style="
                        
                        ">Indemnity</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You agree to indemnify (in other words,
                        compensate for all and any losses incurred), pay the defense costs of, and hold
                        the Company Parties and their employees, officers, directors, agents,
                        contractors, and other representatives harmless from all claims, demands,
                        actions, losses, liabilities, costs and expenses (including, without
                        limitation, attorneys’ fees, costs, and expert witnesses’ fees) that arise out
                        of or in any way are connected with: (a) your access to or use of the Services;
                        or (b) any claim that, if true, would constitute a breach by you of this TERMS
                        OF SERVICE. You agree to reimburse us for any payments made or loss suffered by
                        us, whether in a court judgment or settlement, based on any matter covered by
                        this Section&nbsp;20.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref523929234"></a><a name="_Toc77497124"></a><a name="_Toc1401386"></a><a name="_Toc19737878"></a><a name="_Toc1052399"></a><span style="mso-bookmark:_Ref523929234"><b><span  style="
                        ">21.</span></b></span><span style="mso-bookmark:_Ref523929234"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref523929234"><b><span  style="
                        
                        ">Termination</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">To the fullest extent consistent with
                        applicable law, we may suspend, modify or terminate your access to and use of
                        the Services, including any Game, Virtual Goods, and Content, with no liability
                        or notice to you in the event that (a) we cease providing the Game to similarly
                        situated users generally; (b) you breach any terms of this TERMS OF SERVICE
                        (including the App Store Agreement(s) and our other policies specified in this TERMS
                        OF SERVICE); (c) the owner of the applicable App Store terminates your App
                        Store Account; or (d) we otherwise deem it necessary to suspend or modify your
                        access to and use of the Services or terminate this TERMS OF SERVICE in our
                        sole discretion. You may also terminate this TERMS OF SERVICE by deleting and
                        uninstalling the Game on any and all of your devices or by deleting your App
                        Store Account. &nbsp;A suspension or modification of your access to and use of
                        the Services will result in your inability to access and use some or all
                        features of the Services, as determined by us in our sole discretion. Upon any
                        termination of this TERMS OF SERVICE, the rights granted to you will
                        automatically terminate, you may no longer exercise any of those rights or this
                        TERMS OF SERVICE. &nbsp;Subject to applicable law, we may, in our sole
                        discretion, provide continued access to and use of the Services after such
                        termination.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Where required by applicable law,
                        termination of this TERMS OF SERVICE does not require a court decision to
                        affect termination or a notice served by a court bailiff as a prerequisite to
                        termination.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Except to the extent required by law,
                        all payments and fees are non-refundable under all circumstances, regardless of
                        whether or not this TERMS OF SERVICE has been terminated.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">The following sections will survive
                        termination of this TERMS OF SERVICE:&nbsp;8&nbsp;(first two sentences
                        only),&nbsp;13,&nbsp;19,&nbsp;20,&nbsp;22&nbsp;through&nbsp;25, and this
                        sentence of Section&nbsp;21.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Ref526358948"></a><a name="_Toc77497125"></a><a name="_Toc1401387"></a><a name="_Toc19737879"></a><a name="_Toc1052400"></a><a name="_Ref526359244"></a><span style="mso-bookmark:
                        _Ref526358948"><b><span  style="
                        ">22.</span></b></span><span style="mso-bookmark:_Ref526358948"><b><span  style="
                        
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Ref526358948"><b><span  style="
                        
                        ">Dispute Resolution</span></b></span><b><span  style="
                        ">&nbsp;and
                        Governing Law<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">These Terms and
                        any action related thereto will be governed by the laws of People’s Republic of
                        China without regard to its conflict of laws provisions. Any dispute arising
                        from or in connection with These Terms shall be submitted to Hong Kong
                        International Arbitration Center (“HKIAC”) for arbitration, which shall be
                        conducted in accordance with HKIAC's arbitration rules in effect at the time of
                        applying for arbitration. The seat of arbitration shall be Hong Kong. The
                        language to be used in the arbitral proceedings shall be English. The arbitral
                        award is final and binding upon both parties.</span><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc77497134"></a><a name="_Ref67769504"></a><a name="_Toc1401389"></a><a name="_Toc19737881"></a><a name="_Toc1052402"></a><a name="_Toc77497126"></a><a name="_Toc77497127"></a><a name="_Toc77497128"></a><a name="_Toc77497129"></a><a name="_Toc77497130"></a><a name="_Toc77497131"></a><a name="_Toc77497132"></a><a name="_Toc77497133"></a><b><span  style="
                        ">23.</span></b><b><span  style="
                        ">&nbsp;&nbsp;</span></b><b><span  style="
                        ">No Assignment<o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">You may not assign or transfer this TERMS
                        OF SERVICE, by operation of law or otherwise, without our prior written
                        consent. Any attempt by you to assign or transfer this TERMS OF SERVICE,
                        without such consent, will be null and void. Notwithstanding the title of this
                        Section, we may freely assign or transfer this TERMS OF SERVICE without
                        restriction. Subject to the foregoing, this TERMS OF SERVICE will bind and
                        inure to the benefit of the parties, their successors and permitted assigns.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc1052403"></a><a name="_Toc77497135"></a><a name="_Toc1401390"></a><a name="_Toc19737882"></a><span style="mso-bookmark:_Toc1052403"><b><span  style="
                        
                        ">24.</span></b></span><span style="mso-bookmark:_Toc1052403"><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc1052403"><b><span  style="
                        
                        ">Miscellaneous</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  class="index" style="
                        ">(a)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Entire Agreement</span></u><i><span  style="
                        ">.&nbsp;</span></i><span  style="
                        ">This TERMS OF
                        SERVICE and any other document or information referred to in this TERMS OF
                        SERVICE constitutes the entire and exclusive understanding between you and us
                        regarding the Services and supersede any and all prior oral or written
                        understandings or agreements between you and us regarding the Services.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><a name="_Ref67769514"><span class="index" style="
                        ">(b)</span></a><span style="mso-bookmark:_Ref67769514"><span  style="
                        
                        ">&nbsp;</span></span><span style="mso-bookmark:_Ref67769514"><u><span  style="
                        
                        ">Language</span></u></span><span style="mso-bookmark:_Ref67769514"></span><i><span  style="
                        ">.&nbsp;</span></i><span  style="
                        ">The original language
                        of this TERMS OF SERVICE is in English; any translations are provided for
                        reference purposes only. To the maximum extent permitted by applicable law, you
                        waive any right you may have under the law of your country to have this TERMS
                        OF SERVICE written or construed in any other language.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(c)</span><span  style="
                        "></span><u><span  style="
                        ">Severability</span></u><i><span  style="
                        ">.&nbsp;</span></i><span  style="
                        ">This TERMS OF
                        SERVICE describes certain legal rights. You may have other rights under the
                        laws of your jurisdiction. This TERMS OF SERVICE does not change your rights
                        under the laws of your jurisdiction if the laws of your jurisdiction do not
                        permit it to do so. As noted above, limitations and exclusions of warranties
                        and remedies in this TERMS OF SERVICE may not apply to you because your
                        jurisdiction may not allow them in your particular circumstance. In the event
                        that certain provisions of this TERMS OF SERVICE are held by a court or
                        tribunal of competent jurisdiction to be unenforceable, those provisions will
                        be enforced only to the furthest extent possible under applicable law and, the
                        remaining terms of this TERMS OF SERVICE will remain in full force and effect.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(d)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">No Waiver</span></u><i><span  style="
                        ">.&nbsp;</span></i><span  style="
                        ">Your and our
                        actions or inactions will not create any other rights under this TERMS OF
                        SERVICE except as what is explicitly written within this TERMS OF SERVICE.<i>&nbsp;</i>Our
                        failure to enforce any right or provision of this TERMS OF SERVICE will not be
                        considered a waiver of such right or provision. The waiver of any such right or
                        provision will be effective only if in writing and signed by one of our duly
                        authorized representatives. Except as expressly set forth in this TERMS OF
                        SERVICE, the exercise by either party of any of its remedies under this TERMS
                        OF SERVICE will be without prejudice to its other remedies under this TERMS OF
                        SERVICE or otherwise.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(e)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Your Status</span></u><span  style="
                        ">.&nbsp;You are
                        not, nor acting on behalf of anyone who is:<o:p></o:p></span></p>
                        
                        <p  style="margin-bottom:
                        10.0pt;"><span  style="
                        ">a.</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">subject to
                        sanctions or export restrictions maintained by the United Nations, People’s
                        Republic of China, United States (<i>e.g.</i>, the Specially Designated
                        Nationals and Blocked Persons List (“SDN List”) or the Entity List), the United
                        Nations Security Council, the United Kingdom (including the Consolidated List
                        of Financial Sanctions Targets), the European Union and any Member State
                        thereof (including the Consolidated List of Persons, Groups and Entities
                        Subject to Financial Sanctions), or any other list of restricted
                        persons&nbsp; maintained by any authority with jurisdiction over
                        you (any person so listed being a “<b>Restricted Person</b>”);<o:p></o:p></span></p>
                        
                        <p  style="margin-bottom:
                        10.0pt;"><span  style="
                        ">b.</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">operating from
                        or located or resident in a country or territory that is the target of
                        comprehensive sanctions (“<b>Embargoed Territories</b>”).<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(f)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">General Trade
                        Compliance</span></u><span  style="
                        ">. In connection
                        with your use of the Services, you will comply with all applicable export
                        controls and economic sanctions laws and regulations of the United Nations,
                        People’s Republic of China, United States, European Union, United Kingdom, and
                        other applicable government authorities (collectively, “<b>Trade Laws</b>”).
                        You agree not to engage in any activities in connection with the use of the
                        Services that would violate Trade Laws or that would risk placing us in breach
                        of any Trade Laws. If we have reasons to believe that you are a Restricted
                        Person, are in or a resident of Embargoed Territories, or otherwise engaging in
                        activities that violate Trade Laws or would risk placing us in breach of any
                        Trade Laws, we may, at our sole discretion, take any and all relevant actions,
                        such as requesting you to cease the conduct that violates Trade Laws, disabling
                        or suspending Services, terminating Services with immediate eﬀect, or other
                        remedial actions.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span class="index" style="
                        ">(g)</span><span  style="
                        ">&nbsp;</span><u><span  style="
                        ">Third-Party
                        Rights</span></u><i><span  style="
                        ">.</span></i><span  style="
                        ">&nbsp;Except as
                        described in Section&nbsp;12, a person who is not a party to this TERMS OF
                        SERVICE will have no right under to enforce any of its terms.<o:p></o:p></span></p>
                        
                        <p  class="title-big"><a name="_Toc77497136"></a><a name="_Toc1401391"></a><a name="_Toc1052404"></a><a name="_Ref523929250"></a><span style="mso-bookmark:_Toc77497136"><b><span  style="
                        
                        ">25.</span></b></span><span style="mso-bookmark:_Toc77497136"><b><span  style="
                        ">&nbsp;&nbsp;</span></b></span><span style="mso-bookmark:_Toc77497136"><b><span  style="
                        
                        ">Contact Information</span></b></span><b><span  style="
                        "><o:p></o:p></span></b></p>
                        
                        <p  style="
                        "><span  style="
                        ">If you have any questions about this TERMS
                        OF SERVICE or have any requests for resolving issues arising from or in
                        connection with this TERMS OF SERVICE, we encourage you to primarily contact us
                        through the support features within each APLUS game, so we can reply to you
                        more quickly. Alternatively, you may contact:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Name of the controller: [SUSUMU TANAKA]<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Address: Toranomon Hills Mori Tower 8F
                        1-23-1 Toranomon, Minato-ku, Tokyo Attn: Customer Service Department<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">email: </span><span  >support@sfduelmobile.zendesk.com</span><span  style="
                        "><o:p></o:p></span></p>
                        
                        
                        <p class="title_policy"><b><span  style="
                        ">Schedule B-1:
                        App Store Terms</span></b><span  ><o:p></o:p></span></p>
                        
                        <p  align="left" style="margin-bottom:
                        8.0pt;text-align:left;"><span  style="
                        ">If you download
                        a Game from the App Store, then notwithstanding anything in this TERMS OF
                        SERVICE to the contrary,&nbsp;you acknowledge and agree that:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">We, and not the
                        App Store, are solely responsible for the Game.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">The App Store
                        has no obligation to provide any Game maintenance or support.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">If the Game
                        cannot meet its warranties (if any), you may notify the App Store and the App
                        Store will refund you the purchase price of the Game (if applicable) and, to
                        the maximum extent permitted by applicable law, the App Store will have no
                        other warranty obligation whatsoever with respect to the Game. Any other
                        claims, losses, liabilities, damages, costs or expenses attributable to any
                        failure to conform to any warranty will be our sole responsibility.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">The App Store is
                        not responsible for addressing any claims you have or any claims of any third
                        party relating to the Game or your possession and use of the Game, including,
                        without limitation: (i)&nbsp;product liability claims; (ii) any claim that the
                        Game fails to conform to any applicable legal or regulatory requirement; and
                        (iii) claims arising under consumer protection or similar legislation.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">In the event of
                        any third party claim that the Game or your possession and use of the Game
                        infringes that third party’s intellectual property rights, we will be solely
                        responsible for the investigation, defense, settlement and discharge of any
                        such intellectual property infringement claim to the extent required by this TERMS
                        OF SERVICE.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">The App Store,
                        and its subsidiaries, are third party beneficiaries of this TERMS OF SERVICE
                        and upon your acceptance of this TERMS OF SERVICE, the App Store will have the
                        right to enforce this TERMS OF SERVICE against you as a third-party beneficiary
                        thereof.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">You represent
                        and warrant that (i) you are not located in a country that is subject to a U.S.
                        Government embargo, or that has been designated by the U.S. Government as a
                        terrorist-supporting country; and (ii) you are not listed on any U.S.
                        Government list of prohibited or restricted parties.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">You must also
                        comply with all applicable third party terms of service or similar agreement
                        when using the Game.<o:p></o:p></span></p>
                        
                        <p class="title_policy"><b><span  style="
                        "><br clear="all">
                        Schedule C-1: Streaming Policy</span></b><span  style="
                        
                        "><o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">Introduction</span></u></i><i><span  style="
                        ">.</span></i><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">We hope that you enjoy our games and
                        support your interest in producing game-play videos, whether live or recorded
                        (“<b>Videos</b>”)</span><span>,</span><span  style="
                        ">&nbsp;that share
                        your gaming experiences with others, using images, video, sound effects,
                        in-game music or other assets from our games (“<b>Content</b>”). Please note
                        however, that in most cases using our Content without our permission is illegal
                        and a violation of our rights. This policy helps inform you of the limited
                        rights we grant you to share your experience with our Content with the public
                        in your Videos.<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">Your use of our Content in Videos must
                        be limited to non-commercial purposes, except as expressly stated under this
                        Streaming Policy</span></u></i><i><span  style="
                        
                        ">:</span></i><span  style="
                        
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">You may only use our Content in your Videos
                        for non-commercial uses, except as we expressly state under this Streaming
                        Policy. As such, you may not license your Video which leverages our Content to
                        any company or anyone else for a fee or other form of compensation or for any
                        other commercial use without first receiving our written authorization to do
                        so. Note that we reserve the right to use our own Videos for commercial
                        purposes. Further, any of your Videos that leverage our Content must contain
                        commentary, gameplay, or sufficient originality to make it, in our sole
                        discretion, educational or promotional. Examples of Videos that would NOT
                        qualify under this policy are clips of cut-scenes from games or recordings of a
                        particular game’s soundtrack (without any commentary discussing the cut-scene
                        or soundtrack).<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">We do however permit you to receive
                        payment based on the following two methods, provided you comply with the other
                        portions of this Streaming Policy:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Monetization of
                        your Video that leverages our Content through advertisements served by the
                        platform which hosts your Video such as YouTube or Twitch (a “<b>Platform</b>”).<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Receiving
                        donations via a donation link posted on your profile or in the Video
                        description on a Platform.<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">How you may distribute your Video</span></u></i><i><span  style="
                        ">:</span></i><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Subject to the terms of the applicable TERMS
                        OF SERVICE and this Streaming Policy, you may create Videos using our Content,
                        and you’re free to distribute such Videos on websites where viewers are
                        permitted to view these Videos without any charge of any kind. We understand that
                        some websites may offer paid services. Provide that the website that hosts
                        these Videos provides a free method to allow viewers to view them, you may
                        distribute the Videos on that website.<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">What you may not include in your Video
                        that leverages our Content</span></u></i><i><span  style="
                        
                        ">:</span></i><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">You may not include in any Video (nor
                        anywhere linked near or on the same webpage as the Video) any content that is
                        prohibited as User Content under the TERMS OF SERVICE and the following:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Anything that
                        could imply that the Video is produced by us or that we endorse you or your
                        Video (unless you have an endorsement relationship with us as covered by a
                        separate written or other agreement);<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Any information
                        related to cheats, hacks, exploits, bugs, or third party programs, including
                        links to any of the foregoing; nor<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Uses of our
                        Content that breach applicable law or are derogatory to us or that, in our
                        discretion, may damage the value, goodwill, or reputation of us, our
                        affiliates, our products, Content, or brands.<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Any behavior or
                        conduct that violates public morals and ethics.<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">Ratings – keep your Videos appropriate
                        as follows</span></u></i><i><span  style="
                        
                        ">:</span></i><span  style="
                        
                        "><o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">Your Video should match the ratings
                        guidelines for the game it relates to and must not in any event contain any
                        content that would violate the “T” rating of the Entertainment Software Rating
                        Board (“<b>ESRB</b>”) or the “16” rating of the Pan European Game Information
                        (“<b>PEGI</b>”).<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">Disclosure – you must identify us as the
                        copyright holder of our Content and disclaim our endorsement</span></u></i><span  style="
                        ">:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">In any Video that leverages our Content
                        that you provide, you must include a prominent disclaimer (either at the
                        beginning of the Video or, if live-streaming, near the Video in a visible font)
                        as follows:<o:p></o:p></span></p>
                        
                        <p  style="
                        "><span  style="
                        ">·</span><span  style="
                        ">&nbsp;&nbsp;&nbsp;</span><span  style="
                        ">Portions of the
                        content provided here, including trademarks and copyrights and any other
                        intellectual property rights, are owned or held by&nbsp;</span><a name="_Hlk119423940"><span  style="
                        ">A PLUS JAPAN Inc.</span></a><span style="mso-bookmark:_Hlk119423940"></span><span  style="
                        
                        ">&nbsp;or its licensor(s) (“APLUS”)&nbsp;and
                        all rights in and to the same are reserved by&nbsp;APLUS. This content is not
                        official&nbsp;APLUS&nbsp;content and is not endorsed or approved by&nbsp;APLUS.&nbsp;<o:p></o:p></span></p>
                        
                        <p  class="title-small" style="
                        "><i><u><span  style="
                        ">Some additional important information</span></u></i><i><span  style="
                        ">:</span></i><span  style="
                        "><o:p></o:p></span></p>
                        
                        <p  align="left" style="margin-bottom:
                        8.0pt;text-align:left;"><span  style="
                        ">As solely
                        determined by us, we may terminate your right to host, distribute or otherwise
                        make available a Video that leverages our Content for business or other reasons
                        without notice or liability to you.&nbsp; In such cases, we may (but do not
                        have to) contact you or applicable websites or Platforms regarding terminating
                        such rights to any such Video.<o:p></o:p></span></p>
                        
                        </div>



                </div>
            </div>
        </div>
    </div>

        </body>
        </html>

    `;
}

