import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import NavView from '../component/NavView';
import { getImgPath } from '../store/JSTool';
import LeftDownload from '../component/LeftDownload';
import RightBottom from '../component/RightBottom';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';

import $ from 'jquery';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Footer from './Footer';

export default function DownloadPage() {

    let countTmp = 0;
    let isFinish = false;
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        preloadImages();
        $("#home_page_view").on('scroll', () => {
            let x = $('#home_page_view').scrollTop();
            processScroll(x);
        });

        //平台
        const pt = searchParams.get('platform');
        if (!JSTool.isEmpty(pt)) {
            dispatch(settingActions.setPlatform(pt));
        }
    }, []);

    function processScroll(x) {
        //显示隐藏
        let h = settingData.innerHeight < 500 ? 500 : settingData.innerHeight;
        if (x >= h - 80) {
            JSTool.setViewHidden(true);
        } else {
            JSTool.setViewHidden(false);
        }

         //导航颜色
        const height0 = $("#scroll_to0").height();
        const height1 = $("#page1").height();
        const height3 = $("#page3").height();

        // console.log('x:' + x + ' height0:' + height0 + ' height1:' + height1+ ' height2:' + height2+ ' height3:' + height3
        // + ' height4:' + height4
        // );

        if (x < height0 - 80) {
            if(settingObj.navIndex != 0){
                dispatch(settingActions.setNavIndex(0));
            }
            return;
        }
        if (x < height0 + height1 -80) {
            if(settingObj.navIndex != 1){
                dispatch(settingActions.setNavIndex(1));   
            }
            return;
        }
 
        if (x < height0 + height1 + height3 - 50) {
            if(settingObj.navIndex != 3){
                dispatch(settingActions.setNavIndex(3));   
            }
            return;
        }
       
    }

    function preloadImages() {
        let mainfest = [
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_1.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_2.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_3.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_4.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_5.jpg",

            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role5_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role1_2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role7_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role0_2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role4_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role0_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role6_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role3_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role1_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Ainz_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Ainz_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_left_1.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_right_1.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Aura_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Aura_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_right2.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Demiurge_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Demiurge_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Mare_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Mare_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Momon_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Momon_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Sebas_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Sebas_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Shalltear_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Shalltear_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/alert_bg.png"
        ];
      
        var preload = {
            startPreload: function () {
                var preload = new window.createjs.LoadQueue(true);
                // preload.installPlugin(window.createjs.SOUND);
                // preload.installPlugin(window.createjs.VIDEO);

                preload.addEventListener("progress", this.handleFileProgress);
                preload.addEventListener("complete", this.loadComplete);

                preload.setMaxConnections(10);
                preload.loadManifest(mainfest);
            },

            handleFileProgress: function (event) {
                const num = Math.ceil(event.loaded * 100);
                if (num > countTmp) {
                    countTmp = num;
                    // console.log("handleFileProgress: " + num);
                }
            },

            loadComplete: function () {
                if (!isFinish) {
                    countTmp = 100;
                    isFinish = true;
                    // console.log("loadComplete");
                }
            }
        }
        preload.startPreload();
    }

    return (
        <Stack
            sx={{
                width: 1,
                height: settingData.innerHeight,
                userSelect: 'none'
            }}>
            <NavView isDownloadPage={true}/>
            <LeftDownload />
            <RightBottom />

            <Stack
                id='home_page_view'
                sx={{
                    width: 1,
                    overflow: 'auto',
                }}>
                <Stack>
                    <Page0 anchorId={'scroll_to0'} isDownload={true}/>
                    <Page1 anchorId={'scroll_to1'} isDownload={true}/>
                    <Page3 anchorId={'scroll_to3'} isDownload={true}/>
                    <Footer />
                </Stack>
            </Stack>
        </Stack>
    );
}

